import { initializeApp } from "firebase/app";
// import { getAnalytics, logEvent } from "firebase/analytics";
import { browserSessionPersistence, connectAuthEmulator, getAuth, setPersistence } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const app = initializeApp({
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: "### FIREBASE AUTH DOMAIN ###",
});
// const analytics = getAnalytics(app);
// logEvent(analytics, 'notification_received');
export const functions = getFunctions(app);
export const auth = getAuth();

setPersistence(auth, browserSessionPersistence)
  .then(() => {
    // Existing and future Auth states are now persisted in the current
    // session only. Closing the window would clear any existing state even
    // if a user forgets to sign out.
    // ...
    // New sign-in will be persisted with session persistence.
    return 
  })
  .catch((error) => {
    // Handle Errors here.
  });

if (window.location.hostname === "localhost") {
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectAuthEmulator(auth, "http://localhost:9099");
}
