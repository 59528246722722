import React, { useState } from "react";

export default function CollapsibleContainer(props) {
  const { title, content } = props;
  const [collapseContent, setCollapseContent] = useState(false);

  return (
    <div className="collapsible-container">
      <div className="option-1">
        <label
          onClick={() => {
            setCollapseContent(!collapseContent);
          }}
          className={`collapsible-tile ${collapseContent ? "rotate-arrow" : "arrow"}`}
        >
          {title}
        </label>

        <div
          className={`option-1-content ${
            collapseContent ? "show-content" : "hide-content"
          }`}
        >
          {content}
          
        </div>
      </div>
    </div>
  );
}
