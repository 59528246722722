import React, { useEffect, useState } from "react";

export default function DateSelection(props) {

  const {callParentFunction} = props
  const [todayDate, setTodayDate] = useState("");

  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    setTodayDate(yyyy + "-" + mm + "-" + dd);
  }, []);

  return (
    <div className="date-selection-container">
      <div className="day-title">
        <label>Select a day: </label>
      </div>

      {todayDate && (
        <input
          type="date"
          min={todayDate}
          onChange={(event) => {
            callParentFunction(event.target.value);
          }}
        />
      )}
    </div>
  );
}
