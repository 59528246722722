import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { functions } from "../../Firebase";
import userAtom from "../../recoil/atoms/user";
import CreditCard from "../credit-card";
import ErrorPopup from "../error-popup";
import LoadingSpinner from "../loading-spinner";

export default function PaymentForm(props) {
  const { callParentFunction, callParentNextFunc } = props;

  const [cardError, setCardError] = useState(false);
  const [cardInputError, setCardInputError] = useState(true);
  const [cardInputErrorMessage, setCardInputErrorMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [addPaymentMethodLoading, setAddPaymentMethodLoading] = useState(false);
  const [lastFour, setLastFour] = useState("");
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [savedPaymentMethods, setSavedPaymentMethods] = useState([]);

  const stripe = useStripe();
  const elements = useElements();

  const userInfo = useRecoilValue(userAtom);

  const attachPaymentMethodToCustomer = httpsCallable(
    functions,
    "attachPaymentMethodToCustomer"
  );

  const getPaymentMethodsByCustomerId = httpsCallable(
    functions,
    "getPaymentMethodsByCustomerId"
  );

  const detachPaymentMethodFromCustomer = httpsCallable(
    functions,
    "detachPaymentMethodFromCustomer"
  );

  function resetForm() {
    setCardError(false);
    setMessage("");
    setAddPaymentMethodLoading(false);
    setLastFour("");
    setPaymentMethodId("");
    setExpirationDate("");
    setCardInputErrorMessage(false);
    elements.getElement(CardElement).clear();
  }

  const cardOptions = {
    style: {
      base: {
        color: "#000000",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#000000",
        },
      },

      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#EB5757",
        iconColor: "#EB5757",
      },
    },
    hidePostalCode: true,
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  /**
   * Retrieves all the credit cards saved in the customers account.
   */
  const fetchPaymentMethods = async () => {
    setMessage("Loading saved credit cards...");
    setAddPaymentMethodLoading(true);

    await getPaymentMethodsByCustomerId(userInfo.stripe.customerId)
      .then((response) => {
        setSavedPaymentMethods(response.data.data);
        setAddPaymentMethodLoading(false);
      })
      .catch((error) => {
        setAddPaymentMethodLoading(false);
        setMessage(
          "Something went wrong while retrieving your payment methods, please try again later"
        );
        setCardError(true);
      });
  };

  /**
   * Deletes the payment method from stripe customer.
   * @param {paymentMethodId} paymentMethodId
   */
  async function removePaymentMethod(paymentMethodId) {
    
    setMessage("Deleting credit card from your account...");
    setAddPaymentMethodLoading(true);
    await detachPaymentMethodFromCustomer(paymentMethodId)
      .then((response) => {
        setAddPaymentMethodLoading(false);
        fetchPaymentMethods();
        setLastFour("");
        setExpirationDate("");
      })
      .catch((error) => {
        setAddPaymentMethodLoading(false);

        setMessage(
          "Something went wrong while removing the credit card, please try again later"
        );
        setCardError(true);
      });
  }

  /**
   * Attaches credit card into the customer in stripe.
   */
  async function addCreditCard() {
    if (cardInputError) {
      setCardInputErrorMessage(true);
    } else {
      setMessage("Adding credit card to your account...");
      setAddPaymentMethodLoading(true);
      const cardElement = elements.getElement(CardElement);

      let paymentMethod = await buildPaymentMethodRequest(cardElement);

      await attachPaymentMethodToCustomer(paymentMethod)
        .then((response) => {
          elements.getElement(CardElement).clear();
          fetchPaymentMethods();
          setAddPaymentMethodLoading(false);
        })
        .catch((error) => {
          if (error.code === "functions/already-exists") {
            setMessage(
              "Card already exists in your account, please try another card."
            );
          } else {
            setMessage(
              "Something went wrong while adding your credit card to your account, please try again later"
            );
          }

          setCardError(true);
          setAddPaymentMethodLoading(false);
        });
    }
  }

  /**
   * Updates the states for card selection.
   * @param {ccLastFour} ccLastFour
   * @param {ccExpirationDate} ccExpirationDate
   */
  function updateCardSelection(ccLastFour, ccExpirationDate, paymentId) {
    setLastFour(ccLastFour);
    setExpirationDate(ccExpirationDate);
    setPaymentMethodId(paymentId);
    callParentFunction(ccLastFour, paymentId);
  }

  /**
   * Creates a payment method request in stripe.
   * @param {cardElement} cardElement
   * @returns
   */
  async function buildPaymentMethodRequest(cardElement) {
    return await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
  }

  /**
   * Checks is the Card Element input is completed and no errors
   * @param {event} event
   */
  function handleCardInput(event) {
    if (event.complete && !event.error) {
      setCardInputError(false);
      setCardInputErrorMessage(false);
    } else {
      setCardInputError(true);
    }
  }

  return (
    <div className="payment-form-container">
      {addPaymentMethodLoading && <LoadingSpinner message={message} />}
      {cardError && (
        <ErrorPopup
          message={message}
          closePopup={() => {
            resetForm();
          }}
        />
      )}
      <label className="bold">Add Credit Card</label>
      <label className="note">
        <span className="bold">Note:</span> No charges will be applied unless
        you missed your appointment.
      </label>

      <div className="stripe-card-element">
        <CardElement
          options={cardOptions}
          onChange={(event) => {
            handleCardInput(event);
          }}
        />
        <button
          className="add-cc-button"
          onClick={() => {
            addCreditCard();
          }}
        >
          Add credit card
        </button>
        {cardInputErrorMessage && (
          <label className="error-cc-message">
            Please, enter a valid c.c. number.
          </label>
        )}
      </div>

      {savedPaymentMethods.length !== 0 && (
        <div className="saved-cards-container">
          <span className="payment-title">Select Payment Method</span>
          {savedPaymentMethods.map((creditCard) => {
            const expMonth =
              creditCard.card.exp_month >= 10
                ? creditCard.card.exp_month
                : "0" + creditCard.card.exp_month;

            return (
              <CardsContainer
                creditCard={creditCard}
                key={creditCard.card.last4}
                updateCardSelection={updateCardSelection}
              />

              // <CreditCardContainer
              //   key={creditCard.card.last4}
              //   lastFour={creditCard.card.last4}
              //   expirationDate={expMonth + "/" + creditCard.card.exp_year}
              //   updateCardSelection={updateCardSelection}
              //   deleteCreditCard={removePaymentMethod}
              //   paymentMethodId={creditCard.id}
              // />
            );
          })}
          <div className="cards-actions">
            <button
              className="delete-card"
              onClick={() => {
                removePaymentMethod(paymentMethodId);
              }}
            >
              Delete Card
            </button>
            <button
              className="use-card"
              disabled={
                lastFour === "" &&
                paymentMethodId === "" &&
                expirationDate === ""
              }
              onClick={() => {
                callParentNextFunc();
              }}
            >
              Use Card
            </button>
          </div>
        </div>
      )}

      {/* {lastFour !== "" && expirationDate !== "" && (
        <div className="card-selected">
          <div className="card-selected-title">
            <span className="bold">Card Selected</span>
          </div>
          <div className="card-selected-content">
            <span className="bold">Last four:</span>
            <span> {lastFour}</span>
          </div>
          <div className="card-selected-content">
            <span className="bold">Expiration Date: </span>
            <span>{expirationDate}</span>
          </div>
        </div>
      )} */}
    </div>
  );
}

function CardsContainer(props) {
  const { creditCard, updateCardSelection } = props;

  const expDate =
    creditCard.card.exp_month < 12
      ? "0" + creditCard.card.exp_month + "/" + creditCard.card.exp_year
      : creditCard.card.exp_month + "/" + creditCard.card.exp_year;

  return (
    <div className="cards-radio-buttons" key={creditCard.exp_month}>
      <div className="radios">
        <input
          type="radio"
          name="credit-card"
          id="credit-card"
          key={creditCard.brand}
          onClick={() => {
            updateCardSelection(creditCard.card.last4, expDate, creditCard.id);
          }}
        />
      </div>

      <CreditCard
        cardInfo={creditCard.card}
        for="credit-card"
        key={creditCard.last4}
      />
    </div>
  );
}
