import {
  CancelAppointmentForm,
  FrequentlyAskedQuestions,
  PageIntro
} from "../../components";

export default function CancelAppointmentPage() {
  return (
    <div>
      <PageIntro
        miniTitle={"Sorry about your cancellation"}
        title={"Cancel Appointment"}
        messageContent={
          "Please fill the information below to complete the cancellation of your appointment. If your cancellation is within 24hours of your appointment, a cancellation fee will applied; Otherwise, no charges will be made."
        }
        pageName="cancel"
        buttonsVisible={true}
      />
      <CancelAppointmentForm />
      <FrequentlyAskedQuestions />
    </div>
  );
}
