import { httpsCallable } from "firebase/functions";
import { React, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { functions } from "../../Firebase";
import userAtom from "../../recoil/atoms/user";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../utils/common-functions";
import ErrorPopup from "../error-popup";
import LoadingSpinner from "../loading-spinner";
import SuccessPopup from "../success-popup";
export default function MyProfileForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [phoneError, setPhoneError] = useState(false);

  const [updateInfoLoading, setUpdateInfoLoading] = useState(false);
  const [updateInfoError, setUpdateInfoError] = useState(false);
  const [updateInfoSuccess, setUpdateInfoSuccess] = useState(false);
  const [message, setMessage] = useState(false);

  const user = useRecoilValue(userAtom);
  const setUserInfo = useSetRecoilState(userAtom);

  const updateUserByIdAPI = httpsCallable(functions, "updateUserById");

  useEffect(() => {
    buildUserData(user);
  }, []);

  /**
   * Builds the display user information.
   */
  function buildUserData(data) {
    setFirstName(data.name.first);
    setLastName(data.name.last);
    setEmail(data.contact.communication.email);
    setPhoneNumber(data.contact.communication.phone);
  }

  /**
   * Checks if all fields are valid, and if so, then it attempts
   * to update user's information.
   */
  function updateInformation() {
    if (!anyInformationChange()) {
      setUpdateInfoError(true);
      setMessage("All the information is the same.");
    } else if (validateForm()) {
      updateUserById();
    } else {
      setUpdateInfoError(true);
      setMessage(
        "There are some invalid fields in your request. Please, verify the information and try again."
      );
      buildUserData(user);
    }
  }

  /**
   * Closes any popup window and clears the message state.
   */
  function closePopup() {
    setUpdateInfoError(false);
    setUpdateInfoSuccess(false);
    setUpdateDisabled(true);
    setMessage("");
    setPhoneError(false);
  }

  /**
   * Calls cloud function to attempt to update user's information.
   */
  async function updateUserById() {
    setUpdateInfoLoading(true);

    await updateUserByIdAPI(buildUpdateRequest())
      .then((response) => {
        setUserInfo(response.data);
        buildUserData(response.data);
        setUpdateDisabled(true);
        setUpdateInfoLoading(false);
        setUpdateInfoSuccess(true);
        setMessage("Your information has been updated successfully!");
      })
      .catch((error) => {
        buildUserData(user);
        setUpdateInfoLoading(false);
        setUpdateInfoError(true);
        setMessage(
          "An error error has occurred while updating your information. Please, try again later."
        );
      });
  }

  /**
   * Build data for the cloud function.
   * @returns
   */
  function buildUpdateRequest() {
    return {
      firstName,
      lastName,
      email,
      phoneNumber,
    };
  }

  /**
   * Checks if any of the information changed
   * against the authentication context.
   * @returns
   */
  function anyInformationChange() {
    return (
      firstName !== user.name.first ||
      lastName !== user.name.last ||
      email !== user.contact.communication.email ||
      phoneNumber !== user.contact.communication.phone
    );
  }

  /**
   * Checks if all the fields are valid.
   * @returns
   */
  function validateForm() {
    return (
      firstName !== "" &&
      lastName !== "" &&
      validateEmail(email) &&
      validatePhoneNumber(phoneNumber)
    );
  }

  /**
   * Enables the fields to edit them, or submit the changes
   * to update user information.
   */
  function handleUpdateButton() {
    if (updateDisabled) {
      setUpdateDisabled(false);
    } else {
      updateInformation();
    }
  }

  /**
   * Updates phone number state and handles phone validation.
   * @param {value} value
   */
  function updatePhone(value) {
    setPhoneNumber(value);

    if (validatePhoneNumber(value)) {
      setPhoneError(false);
    } else {
      setPhoneError(true);
    }
  }

  /**
   * Disable all fields and builds the user's data again.
   */
  function handleCancelUpdate() {
    buildUserData(user);
    setUpdateDisabled(true);
  }

  return (
    <div className="my-profile-form-container">
      {updateInfoError && (
        <ErrorPopup
          message={message}
          closePopup={() => {
            closePopup();
          }}
        />
      )}
      {updateInfoLoading && (
        <LoadingSpinner message="Updating your information..." />
      )}
      {updateInfoSuccess && (
        <SuccessPopup
          message={message}
          closePopup={() => {
            closePopup();
          }}
        />
      )}
      <div className="title-container">
        <div className="profile-icon-container">
          <img
            src={require("../../assets/images/my-profile-icon.png")}
            alt="pedicure-cartoon"
          />
        </div>

        <label>View and edit your personal information below.</label>
      </div>
      <div className="my-profile-fields-container">
        <div className="names-row">
          <div className="first-name-container">
            <label>First Name</label>
            <input
              type="text"
              id="first-name"
              name="First Name"
              value={firstName}
              disabled={updateDisabled}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
            ></input>
          </div>
          <div className="last-name-container">
            <label>Last Name</label>
            <input
              type="text"
              id="last-name"
              name="Last Name"
              value={lastName}
              disabled={updateDisabled}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
            ></input>
          </div>
        </div>

        <div className="communication-row">
          <div className="email-container">
            <label>Email</label>
            <input
              type="text"
              id="email"
              name="Email"
              value={email}
              disabled={true}
            ></input>
          </div>
          <div className="phone-number-container">
            <label>Phone number</label>
            <input
              type="text"
              id="phone-number"
              name="Phone number"
              value={phoneNumber}
              disabled={updateDisabled}
              onChange={(event) => {
                updatePhone(event.target.value);
              }}
            ></input>
            {phoneError && (
              <label className="invalid-message">Invalid phone number.</label>
            )}
          </div>
        </div>

        <div className="my-profile-buttons-container">
          <button
            className="update-button"
            onClick={() => handleUpdateButton()}
          >
            {updateDisabled ? "Update info" : "Submit changes"}
          </button>

          {!updateDisabled && (
            <button
              className="cancel-button"
              onClick={() => handleCancelUpdate()}
            >
              Cancel
            </button>
          )}
        </div>
      </div>

      <div className="cartoon-img-container">
        <img
          src={require("../../assets/images/pedicure-cartoon.png")}
          alt="pedicure-cartoon"
        />
      </div>
    </div>
  );
}
