import { useState } from "react";
import { buildOptions } from "../../utils/common-functions";
import { services } from "../../utils/variables";

export default function KidsServices(props) {
  const { callParentFunction } = props;

  const [regularPolish, setRegularPolish] = useState("default");
  const [gelPolish, setGelPolish] = useState("default");
  const [regularPolishChange, setRegularPolishChange] = useState("default");
  const [gelPolishChange, setGelPolishChange] = useState("default");

  function updateSelection(service, value) {
    if (service === "regular-polish") {
      setRegularPolish(value);
      setGelPolish("default");
      setRegularPolishChange("default");
      setGelPolishChange("default");
    } else if (service === "gel-polish") {
      setRegularPolish("default");
      setGelPolish(value);
      setRegularPolishChange("default");
      setGelPolishChange("default");
    } else if (service === "regular-polish-change") {
      setRegularPolish("default");
      setGelPolish("default");
      setRegularPolishChange(value);
      setGelPolishChange("default");
    } else if (service === "gel-polish-change") {
      setRegularPolish("default");
      setGelPolish("default");
      setRegularPolishChange("default");
      setGelPolishChange(value);
    }

    updateParentSelection(value);
  }

  /**
   * Assigns the selected value.
   * @param {value} value
   */
  function updateParentSelection(value) {
    value !== "default"
      ? callParentFunction(JSON.parse(value))
      : callParentFunction("");
  }

  return (
    <div className="kids-services-container">
      <div className="kid-services">
        <label>Regular Polish</label>
        <select
          name="regular polish"
          value={regularPolish}
          onChange={(event) =>
            updateSelection("regular-polish", event.target.value)
          }
        >
          <option value="default">Select one...</option>
          {buildOptions(services.kidServices.regularPolish)}
        </select>
      </div>

      <div className="kid-services">
        <label>Gel Polish</label>
        <select
          name="gel polish"
          value={gelPolish}
          onChange={(event) =>
            updateSelection("gel-polish", event.target.value)
          }
        >
          <option value="default">Select one...</option>
          {buildOptions(services.kidServices.gelPolish)}
        </select>
      </div>

      <div className="kid-services">
        <label>Regular Polish Change</label>
        <select
          name="regular polish change"
          value={regularPolishChange}
          onChange={(event) =>
            updateSelection("regular-polish-change", event.target.value)
          }
        >
          <option value="default">Select one...</option>
          {buildOptions(services.kidServices.polishChanges.regularPolish)}
        </select>
      </div>

      <div className="kid-services">
        <label>Gel Polish Change</label>
        <select
          name="gel polish change"
          value={gelPolishChange}
          onChange={(event) =>
            updateSelection("gel-polish-change", event.target.value)
          }
        >
          <option value="default">Select one...</option>
          {buildOptions(services.kidServices.polishChanges.gelPolish)}
        </select>
      </div>
    </div>
  );
}
