import {
  ContentCard,
  PageIntro,
  SplashBox,
} from "../../components";

export default function HomePage() {
  return (
    <div>
      
      <PageIntro
        miniTitle={"Hello, thank you for visiting"}
        title={"Welcome to Lis Nails & Spa"}
        messageContent={
          "We will offer services that provides our customers with wellness, health and relaxation for hands and feet."
        }
        pageName="home"
        buttonsVisible={true}
      />
      <ContentCard
        title={"About Lis Nails & Spa"}
        paragraph={
          "Our goal is to make everyone of all ages feel beautiful and confident! We will offer services that provides our customers with wellness, health and relaxation for hands and feet. Our goal is to provide customers with the best quality of service and the greatest satisfaction. For this reason, our nail salon continually updates new trendy nail art techniques and designs for our valued customers."
        }
        imageType={"foot"}
        reverse={true}
        displayDots={true}
      />
      <ContentCard
        title={"Service Guarantee"}
        paragraph={
          "We offer an 8-day guarantee period for gel, dip and acrylic services where you can return to the salon to have the repairs done free of charge on the same service. Use the online reservation or call to make an appointment."
        }
        imageType={"hands"}
        reverse={false}
        displayDots={true}
      />
      <ContentCard
        title={"Stay safe & stay healthy"}
        paragraph={
          "Professionalism will be our commitment and your satisfaction our promise."
        }
        imageType={"safety"}
        reverse={true}
        displayDots={false}
      />
      <SplashBox />
    </div>
  );
}
