export default function LoadingSpinner(props) {
  const { message } = props;
  return (
    <div className="popup-animation">
      <div className="loader-container">
        <div className="spinner-container">
        <svg className="loader" viewBox="0 0 24 24">
          <circle className="loader__value" cx="12" cy="12" r="10" />
          <circle className="loader__value" cx="12" cy="12" r="10" />
          <circle className="loader__value" cx="12" cy="12" r="10" />
          <circle className="loader__value" cx="12" cy="12" r="10" />
          <circle className="loader__value" cx="12" cy="12" r="10" />
          <circle className="loader__value" cx="12" cy="12" r="10" />
        </svg>
        </div>
        
        <label className="loader-text">{message}</label>
      </div>
    </div>
  );
}
