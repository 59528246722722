import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { validateEmail } from "../../utils/common-functions";
import { auth, functions } from "../../Firebase";
import ErrorPopup from "../error-popup";
import LoadingSpinner from "../loading-spinner";
import { httpsCallable } from "firebase/functions";

export default function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [logInLoading, setLogInLoading] = useState(false);
  const [logInError, setLogInError] = useState(false);
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [resendVerificationEmail, setResendVerificationEmail] = useState(false);
  const [showPasswordToggle, setShowPasswordToggle] = useState(false);

  const navigate = useNavigate();

  const getUserByEmailAPI = httpsCallable(functions, "getUserByEmail");

  function goCreateAccountPage() {
    navigate("/create-account");
    window.scrollTo(0, 0);
  }

  /**
   * Retrieves user from authentication collection.
   * @returns user information
   */
  async function getUserByEmail() {
    let user;

    await getUserByEmailAPI({ userEmail: email })
      .then((response) => {
        user = response.data;
      })
      .catch((error) => {
        handleError(error);
      });

    return user;
  }

  /**
   * Attempts to sign the user in with their credentials.
   */
  async function signIn(event) {
    event.preventDefault();

    if (password === "") {
      setMessage("Password must be entered.");
      setLogInError(true);
    } else if (validateEmail(email)) {
      setLogInLoading(true);
      const user = await getUserByEmail(email);

      if (user === undefined) {
        setMessage("There is not an existing account with email: " + email);
        setLogInError(true);
      } else if (user.emailVerified) {
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in. The data of the user is being set at App.js
            setLogInLoading(false);
          })
          .catch((error) => {
            handleError(error);
          });
      } else {
        setLogInLoading(false);
        setMessage("You need to verified your email before signing in.");
        setLogInError(true);
        setResendVerificationEmail(true);
      }
    } else {
      setEmailError(true);
    }
  }

  /**
   * Resets all form values and states to default values.
   */
  function restForm() {
    setEmail("");
    setPassword("");
    setLogInLoading(false);
    setLogInError(false);
    setMessage("");
    setEmailError(false);
  }

  /**
   * Updates the email state and displays email error message
   * if the email is invalid.
   * @param {value} value
   */
  function updateEmail(value) {
    setEmail(value.trim());
    if (!validateEmail(value.trim())) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }

  /**
   * Updates error message and displays error popup.
   * @param {error} error
   */
  function handleError(error) {
    setLogInLoading(false);
    if ("auth/user-not-found" === error.code) {
      setMessage("There is not an existing account with email: " + email);
      setLogInError(true);
    } else if ("auth/wrong-password" === error.code) {
      setMessage(
        "The password you enter is incorrect. Please try again, thank you!"
      );
      setLogInError(true);
    } else {
      setMessage(
        "An error occurred while trying to sign in, please try again or contact the store."
      );
      setLogInError(true);
    }
  }
  return (
    <form
      onSubmit={(e) => {
        signIn(e);
      }}
    >
      <div className="login-container">
        {logInError && <ErrorPopup message={message} closePopup={restForm} />}
        {logInLoading && <LoadingSpinner message="Logging in..." />}
        <div className="left-image-container">
          <img
            src={require("../../assets/images/login.jpg")}
            alt="company-logo"
          />
        </div>
        <div className="login-form-container">
          <div className="login-title-container">
            <label>Log in</label>
            <div className="login-icon-container">
              <img
                src={require("../../assets/images/login-icon.png")}
                alt="company-logo"
              />
            </div>
          </div>

          <div className="email-container">
            <label>Email address</label>
            <div className="email-input-container">
              <input
                className="email-input"
                type="text"
                id="email"
                name="Email"
                placeholder="example@gmail.com"
                value={email}
                onChange={(event) => {
                  updateEmail(event.target.value);
                }}
              />
            </div>

            {emailError && (
              <label className="email-error">
                Please, enter a valid email.
              </label>
            )}
          </div>
          <div className="password-container">
            <label>Password</label>
            <div className="input-password-container">
              <input
                className="password-input"
                type={showPasswordToggle ? "text" : "password"}
                id="password"
                name="password"
                placeholder="**********"
                value={password}
                autoComplete="on"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />

              {showPasswordToggle ? (
                <img
                  className="eye-image"
                  src={require("../../assets/images/eye.png")}
                  alt="eye-icon"
                  onClick={() => {
                    setShowPasswordToggle(!showPasswordToggle);
                  }}
                />
              ) : (
                <img
                  className="eye-image"
                  src={require("../../assets/images/no-eye.png")}
                  alt="eye-icon"
                  onClick={() => {
                    setShowPasswordToggle(!showPasswordToggle);
                  }}
                />
              )}
            </div>

            <div className="forgot-link-container">
              <label
                className="forgot-password-link"
                onClick={() => {
                  navigate("/forgot-password");
                }}
              >
                Forgot My Password
              </label>
            </div>

            {resendVerificationEmail && (
              <label
                className="forgot-password-link resend-email"
                onClick={() => {
                  navigate("/resend-verification-email");
                }}
              >
                Resend verification email
              </label>
            )}
            <div className="login-buttons-container">
              <button type="submit" className="create-account-button">
                Log in
              </button>
              <h2>
                <span>or</span>
              </h2>
              <button
                onClick={() => {
                  goCreateAccountPage();
                }}
              >
                Create an Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
