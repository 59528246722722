import { useEffect, useState } from "react";

export default function CreditCard(props) {
  const { cardInfo } = props;
  const [cardContainerColor, setCardContainerColor] = useState("");

  useEffect(() => {
    setCardContainerColor(defineColor(cardInfo.brand));
  }, []);

  function defineColor(cardBrand) {
    let color = "";
    if (cardBrand === "amex") {
      color = "#016FD0";
    } else if (cardBrand === "mastercard") {
      color = "#FF5F00";
    } else if (cardBrand === "visa") {
      color = " #1434CB";
    } else if (cardBrand === "discover") {
      color = "#E55C20";
    } else {
      color = "#808080";
    }

    return color;
  }
  return (
    <div
      className="credit-card"
      style={{ backgroundColor: cardContainerColor }}
    >
      <div className="credit-card-info">
        <div className="info-container">
          <span className="card-number">**** **** **** {cardInfo.last4}</span>
          <span className="card-expiration">
            {cardInfo.exp_month < 10
              ? "0" + cardInfo.exp_month
              : cardInfo.exp_month}
            /{cardInfo.exp_year}
          </span>
        </div>
      </div>
      <div className="credit-card-logo">
        {cardInfo.brand === "amex" && (
          <img
            src={require("../../assets/images/american-express-logo.png")}
            alt="back-icon"
          />
        )}
        {cardInfo.brand === "mastercard" && (
          <img
            src={require("../../assets/images/master-card-logo.png")}
            alt="back-icon"
          />
        )}
        {cardInfo.brand === "visa" && (
          <img
            src={require("../../assets/images/visa-logo.png")}
            alt="back-icon"
          />
        )}
        {cardInfo.brand === "discover" && (
          <img
            src={require("../../assets/images/discover-logo.png")}
            alt="back-icon"
          />
        )}
        {cardInfo.brand !== "amex" &&
          cardInfo.brand !== "visa" &&
          cardInfo.brand !== "mastercard" &&
          cardInfo.brand !== "discover" && (
            <img
              src={require("../../assets/images/all-cards.png")}
              alt="back-icon"
            />
          )}
      </div>
    </div>
  );
}
