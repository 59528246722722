import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { validateEmail } from "../../utils/common-functions";
import LoadingSpinner from "../loading-spinner";
import ErrorPopup from "../error-popup";
import SuccessPopup from "../success-popup";
import { functions } from "../../Firebase";
import { httpsCallable } from "firebase/functions";

export default function ForgotPasswordForm() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [resetPasswordLoading, setRestPasswordLoading] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const resetPasswordAPI = httpsCallable(functions, "resetUserPassword");

  function resetForm() {
    setEmail("");
    setEmailError(false);
    setRestPasswordLoading(false);
    setResetPasswordError(false);
    setResetPasswordSuccess(false);
    setMessage("");
  }

  /**
   * If user entered a valid email,then it will send an email to
   * reset their password.
   */
  function resetPassword() {
    if (validateEmail(email)) {
      userRestPassword();
    } else {
      setResetPasswordError(true);
    }
  }

  async function userRestPassword() {
    setRestPasswordLoading(true);

    await resetPasswordAPI({ userEmail: email })
      .then((response) => {
        setRestPasswordLoading(false);
        setResetPasswordSuccess(true);
        setMessage(
          "An email with instructions to reset your password has been sent. Thank you!"
        );
      })
      .catch((error) => {
        if (error.code === "functions/not-found") {
          setMessage(
            "There is no an account associated with this email: " + email
          );
        } else {
          setMessage(
            "Something went wrong while sending you a reset password email, please try again later. Thank you!"
          );
        }

        setRestPasswordLoading(false);
        setResetPasswordError(true);
      });
  }

  /**
   * Sets the email estate and checks if the email is valid.
   * @param {value} value
   */
  function updateEmail(value) {
    setEmail(value);

    if (validateEmail(value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  }

  return (
    <div className="forgot-password-form-container">
      {resetPasswordLoading && (
        <LoadingSpinner message="Sending reset password email..." />
      )}
      {resetPasswordError && (
        <ErrorPopup message={message} closePopup={resetForm} />
      )}
      {resetPasswordSuccess && (
        <SuccessPopup message={message} closePopup={resetForm} />
      )}
      <div className="forgot-pass-card">
        <div className="forgot-pass-title">
          <label>Forgot Password</label>
          <div className="password-icon-container">
            <img
              src={require("../../assets/images/lock-icon.png")}
              alt="company-logo"
            />
          </div>
        </div>
        <div className="title-description">
          <p>
            Enter your email and we'll send you a link to reset your password
          </p>
        </div>
        <div className="form-container">
          <input
            type="text"
            placeholder="Your email here..."
            value={email}
            onChange={(event) => {
              updateEmail(event.target.value);
            }}
          ></input>
          {emailError && <label className="invalid-email">Invalid email</label>}

          <button
            onClick={() => {
              resetPassword();
            }}
          >
            Submit
          </button>
          <div className="back-link-container">
            <img
              src={require("../../assets/images/back-icon.png")}
              alt="back-icon"
            />
            <label
              className="back-link"
              onClick={() => {
                navigate("/login");
              }}
            >
              Back to Login
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
