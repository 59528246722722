import { useEffect, useState } from "react";

export default function AppointmentDetails(props) {
  const { appointment } = props;

  const [time, setTime] = useState("");
  const [services, setServices] = useState("");
  const [price, setPrice] = useState(0);

  useEffect(() => {
    buildDisplayInformation();
  }, []);

  function buildDisplayInformation() {
    console.log(appointment);
    const hour =
      appointment.time.hour > 12
        ? appointment.time.hour - 12
        : appointment.time.hour;
    const minute =
      appointment.time.minute === 0 ? "00" : appointment.time.minute;
    const ampm = appointment.time.hour >= 12 ? "PM" : "AM";

    let displayServices = "";
    let totalPrice = 0;
    if (appointment.services.manicure && appointment.services.manicure !== "") {
      displayServices += appointment.services.manicure.hasOwnProperty(
        "fullServiceName"
      )
        ? " " + appointment.services.manicure.fullServiceName
        : " " + appointment.services.manicure.displayName;
      totalPrice += appointment.services.manicure.price;
    }
    if (appointment.services.pedicure && appointment.services.pedicure !== "") {
      displayServices += appointment.services.pedicure.hasOwnProperty(
        "fullServiceName"
      )
        ? " " + appointment.services.pedicure.fullServiceName
        : " " + appointment.services.pedicure.displayName;
      totalPrice += appointment.services.pedicure.price;
    }

    if (
      appointment.services.kidService &&
      appointment.services.kidService !== ""
    ) {
      displayServices += appointment.services.kidService.hasOwnProperty(
        "fullServiceName"
      )
        ? " " + appointment.services.kidService.fullServiceName
        : " " + appointment.services.kidService.displayName;
      totalPrice += appointment.services.kidService.price;
    }

    if (
      appointment.services.menService &&
      appointment.services.menService !== ""
    ) {
      const typeOfValue = typeof appointment.services.menService;

      if (Array.isArray(appointment.services.menService)) {
        displayServices += buildDisplayNames(appointment.services.menService);
        console.log("about to call...");
        totalPrice += sumServicesPrice(appointment.services.menService);
      } else if (typeOfValue === "object") {
        displayServices += appointment.services.menService.displayName;
        totalPrice += appointment.services.menService.price;
      }
    }

    if (
      appointment.services.addOnsServices &&
      appointment.services.addOnsServices !== ""
    ) {
      const typeOfValue = typeof appointment.services.addOnsServices;

      if (Array.isArray(appointment.services.addOnsServices)) {
        displayServices += buildDisplayNames(
          appointment.services.addOnsServices
        );
        console.log("about to call...");
        totalPrice += sumServicesPrice(appointment.services.addOnsServices);
      } else if (typeOfValue === "object") {
        displayServices += appointment.services.addOnsServices.displayName;
        totalPrice += appointment.services.addOnsServices.price;
      }
    }

    if (
      appointment.services.soakOffService &&
      appointment.services.soakOffService !== ""
    ) {
      displayServices += appointment.services.soakOffService.hasOwnProperty(
        "fullServiceName"
      )
        ? " " + appointment.services.soakOffService.fullServiceName
        : " " + appointment.services.soakOffService.displayName;
      totalPrice += appointment.services.soakOffService.price;
    }

    setPrice(totalPrice);
    setServices(displayServices);
    setTime(hour + ":" + minute + " " + ampm);
  }

  function buildDisplayNames(services) {
    let displayName = "";
    for (var service of services) {
      displayName += service.hasOwnProperty("fullServiceName")
        ? " " + service.fullServiceName
        : " " + service.displayName;
    }

    return displayName;
  }

  function sumServicesPrice(services) {
    console.log(services);
    console.log("HERE");
    let sum = 0;
    for (var service of services) {
      console.log(service.price);
      sum += service.price;
    }

    return sum;
  }

  return (
    <div className="card-content">
      <div className="appt-details">
        <div className="appt-details-description-container">
          <label className="label-description">Customer name:</label>
        </div>
        <div className="label-value-container">
          <label className="label-value">
            {appointment.customer.name.first +
              " " +
              appointment.customer.name.last}
          </label>
        </div>
      </div>
      <div className="appt-details">
        <div className="appt-details-description-container">
          <label className="label-description">Phone number:</label>
        </div>
        <div className="label-value-container">
          <label className="label-value">
            {appointment.customer.communication.phone}
          </label>
        </div>
      </div>
      <div className="appt-details">
        <div className="appt-details-description-container">
          <label className="label-description">Start time:</label>
        </div>
        <div className="label-value-container">
          <label className="label-value">{time}</label>
        </div>
      </div>

      <div className="appt-details">
        <div className="appt-details-description-container">
          <label className="label-description">Cost:</label>
        </div>
        <div className="label-value-container">
          <label className="label-value">${price}</label>
        </div>
      </div>
      <div className="appt-details">
        <div className="appt-details-description-container">
          <label className="label-description">Status:</label>
        </div>
        <div className="label-value-container">
          <label className="label-value">{appointment.status}</label>
        </div>
      </div>
      <div className="appt-details">
        <div className="appt-details-description-container">
          <label className="label-description">Services:</label>
        </div>
        <div className="label-value-services-container">
          <label className="label-value">{services}</label>
        </div>
      </div>
    </div>
  );
}
