import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { functions } from "../../../Firebase";
import ErrorPopup from "../../error-popup";
import LoadingBar from "../../loading-bar";
import SuccessPopup from "../../success-popup";

export default function CheckIn(props) {
  const { appointment, closePopup, checkInAppointmentParent } = props;

  const [checkInLoading, setCheckInLoading] = useState(false);
  const [checkInError, setCheckInError] = useState(false);
  const [checkInSuccess, setCheckInSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [confirmCheckIn, setConfirmCheckIn] = useState(false);
  const [confirmNoShow, setConfirmNoShow] = useState(false);
  const [checkInCustomerDisplay, setCheckInCustomerDisplay] = useState(true);

  const checkInAPI = httpsCallable(functions, "appointmentCheckIn");

  async function checkInAppointment(status) {
    setCheckInLoading(true);

    await checkInAPI(buildUpdateRequest(status))
      .then((response) => {
        setCheckInLoading(false);
        checkInAppointmentParent(appointment, status);
        setMessage(
          "The status of the appointment has been changed to " + status
        );
        setCheckInSuccess(true);
        
      })
      .catch((error) => {
        setCheckInLoading(false);
        setMessage(
          "An error occurred while checking in the appointment, please try again later."
        );
        setCheckInError(true);
      });
  }

  function buildUpdateRequest(status) {
    return {
      appointmentId: appointment.id,
      status: status,
    };
  }

  return (
    <div className="card-content">
      {checkInError && (
        <ErrorPopup
          message={message}
          closePopup={() => {
            setCheckInError(false);
          }}
        />
      )}
      {checkInSuccess && (
        <SuccessPopup
          message={message}
          closePopup={() => {
            closePopup();
          }}
        />
      )}

      <div className="appt-details-cancel">
        {checkInCustomerDisplay && (
          <div>
            {appointment.status === "not checked-in" && (
              <p>Did the customer show to their appointment?</p>
            )}
            {appointment.status !== "not checked-in" && (
              <p>
                This appointment cannot be checked in because its status is "
                {appointment.status}"
              </p>
            )}
            {appointment.status === "not checked-in" && (
              <div>
                <button
                  onClick={() => {
                    setConfirmCheckIn(true);
                    setCheckInCustomerDisplay(false);
                  }}
                >
                  CONFIRM
                </button>
                <button
                  className="no-show-button"
                  onClick={() => {
                    setConfirmNoShow(true);
                    setCheckInCustomerDisplay(false);
                  }}
                >
                  NO SHOW
                </button>
              </div>
            )}
          </div>
        )}

        {checkInLoading && <LoadingBar title="Updating appointment's status" />}

        {confirmCheckIn && (
          <div className="confirmation-question">
            <p>Are you sure you want to check in this appointment?</p>
            <button
              onClick={() => {
                checkInAppointment("checked in");
              }}
            >
              Yes, Check in
            </button>
            <button
              className="no-show-button"
              onClick={() => {
                setConfirmCheckIn(false);
                setCheckInCustomerDisplay(true);
              }}
            >
              Cancel
            </button>
          </div>
        )}
        {confirmNoShow && (
          <div className="confirmation-question">
            {appointment.customer.zelle === "" ? <p>Confirm no show. The customer will be charged $20</p> : <p>Verify the Zelle transaction was made, then confirm</p>}
            
            <button
              onClick={() => {
                checkInAppointment("no show");
              }}
            >
              {appointment.customer.zelle ==="" ? "Yes, apply charge" : "Confirm"}
              
            </button>
            <button
              className="no-show-button"
              onClick={() => {
                setConfirmNoShow(false);
                setCheckInCustomerDisplay(true);
              }}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
