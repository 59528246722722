import { useState } from "react";
import { buildOptions } from "../../utils/common-functions";
import { services } from "../../utils/variables";

export default function MenServices(props) {
  const { callParentFunction } = props;

  const [menManicure, setMenManicure] = useState("default");
  const [manicureValue, setManicureValue] = useState("");
  const [menPedicure, setMenPedicure] = useState("default");
  const [pedicureValue, setPedicureValue] = useState("");
  const [servicesSelected, setServicesSelected] = useState([]);

  function updateSelection(service, value) {
    if (service === "manicure") {
      setMenManicure(value);
      setManicureValue(JSON.parse(value));
    } else if (service === "pedicure") {
      setMenManicure("default");
      setMenPedicure(value);
      setPedicureValue(JSON.parse(value));
    }

    updateParentSelection(value);
  }

  /**
   * Assigns the selected value.
   * @param {value} value
   */
  function updateParentSelection(value) {
    value !== "default"
      ? callParentFunction(JSON.parse(value))
      : callParentFunction("");
  }

  function addAddOn(value) {
    if (value) {
      const temp = [...servicesSelected];
      temp.push(value);
      setServicesSelected([...temp]);
      setMenManicure("default");
      setMenPedicure("default");
      callParentFunction([...temp]);
    }
  }

  function removeAddOn(index) {
    const temp = [...servicesSelected];
    temp.splice(index, 1);
    setServicesSelected([...temp]);
    callParentFunction([...temp]);
  }

  return (
    <div className="men-services-container">
      <div className="manicure-men-services">
        <div className="men-services">
          <label>Manicures</label>
          <select
            name="manicure services"
            value={menManicure}
            onChange={(event) =>
              updateSelection("manicure", event.target.value)
            }
          >
            <option value="default">Select one...</option>
            {buildOptions(services.menServices.manicure)}
          </select>
        </div>
        <div className="men-service-button">
          <button
            className="men-add-button"
            onClick={() => {
              addAddOn(manicureValue);
            }}
          >
            add
          </button>
        </div>
      </div>

      <div className="manicure-men-services">
        <div className="men-services">
          <label>Pedicures</label>
          <select
            name="pedicure services"
            value={menPedicure}
            onChange={(event) =>
              updateSelection("pedicure", event.target.value)
            }
          >
            <option value="default">Select one...</option>
            {buildOptions(services.menServices.pedicure)}
          </select>
        </div>
        <div className="men-service-button">
          <button
            className="men-add-button"
            onClick={() => {
              addAddOn(pedicureValue);
            }}
          >
            add
          </button>
        </div>
      </div>
      {servicesSelected.length > 0 && (
        <div className="added-list-container">
          <span>Add Ons selected</span>
          <table>
            {servicesSelected.map((addOn, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <th>{addOn.fullServiceName}</th>
                    <th>
                      <img
                        onClick={() => {
                          removeAddOn(index);
                        }}
                        className="footer-logo-img"
                        src={require("../../assets/images/trash-can.png")}
                        alt="trash-can-icon"
                      />
                    </th>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      )}
    </div>
  );
}
