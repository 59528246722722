import QuestionCard from "./question-card";
import { useNavigate } from "react-router-dom";

export default function FrequentlyAskedQuestions() {

  const navigate = useNavigate()

  function goHomePage() {
    navigate("/")
    window.scroll(0,0)
  }

  return (
    <div className="faq-container">
      <a id="faq-anchor">
      <div className="text-container">
        <h2>Frequently Asked Questions</h2>
        <p>
        Lis Nails & Spa provides an 8-day guarantee period on gel, dip powder, & artificial services in which you may return to the salon to have repairs done free of charge based on same color.
        </p>
        <div className="back-link">
        <img
              src={require("../../assets/images/back-icon.png")}
              alt="back-icon"
            />
            <label
              className="back-link"
              onClick={() => {
                goHomePage()
              }}
            >
              Back to Home Page
            </label>
        </div>
      </div>

      <QuestionCard
        question="How do I make an appointment?"
        answer="Go to the book appointment page, you can click on the Book Appointment button located at the top of the page. Then you need to login, or create an account if you don't have one. After that, you can go through the selection of services, days, and time of your desire appointment."
        purpleBackground={true}
      />

      <QuestionCard
        question="What is the difference between acrylic and dip powder?"
        answer="Dip powder has a special glue that dries on its own and the nail is dipped in the powder of the desired color. Acrylic dries on its own without glue, that is, it is self-curing. In addition, dip powder has proteins and the acrylic does not.
        Both are completely removed with soak off liquid in one session, except that the dip powder is removed every 3 weeks (maximum) and the acrylic can be filled every 3 weeks for 2 months and removed the third time and do a full new set.
        Acrylic is stronger and is recommended for people who use their hands a lot in their daily lives or suffer from onychophagia (biting their nails).
        After seeing all these aspects, neither of the two options is better than the other, both have favorable qualities and applications and neither weakens the natural nails more or less."
        purpleBackground={false}
      />
      <QuestionCard
        question="Do you accept clients without an appointment?"
        answer="To ensure you get the best care possible when you visit our salon, we recommend that you book in advance. We are happy to welcome walk-ins and walk-ins alike; however, by scheduling in advance, you will have access to expanded services that will not be available without a record of your visit."
        purpleBackground={true}
      />
      <QuestionCard
        question="Can I cancel my appointment?"
        answer="Yes, you can cancel by copying the confirmation code from you confirmation email and pasting it under Cancelation Appointment Page."
        purpleBackground={false}
      />
      <QuestionCard
        question="Can I reschedule my appointment?"
        answer="Yes, in order to reschedule your appointment you must first cancel your original appointment, and then book a brand new appointment."
        purpleBackground={true}
      />
      <QuestionCard
        question="Is there a cancellation fee?"
        answer="Any appointments that are cancelled 24 hours prior the appointment date and time will be free of charge. If the appointment are cancelled within 24 hours of your appointment, there will be a $20 charge."
        purpleBackground={false}
      />
      <QuestionCard
        question="What if I am late to the appointment?"
        answer="If you are 10 or more minutes late to your scheduled appointment, I might be required to reschedule your appointment to respect following appointments after yours, and a $20 no show fee will be applied."
        purpleBackground={true}
      />
      <QuestionCard
        question="How long do the services last?"
        answer="It depends of the service you request. A general idea is a complete manicure or pedicure with permanent nail polish 45 min / 1 hour each service. Acrylic nails or dip powder from 1 to 2 hours depending on the design.
        The times vary depending on whether you remove the previous material. If you want a more elaborate design or with gradients, then it will require more time. Your collaboration at the time of care with our professionals is essential to optimize time."
        purpleBackground={false}
      />
      </a>
    </div>
  );
}
