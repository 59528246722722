import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { functions } from "../../Firebase";
import ErrorPopup from "../error-popup";
import LoadingSpinner from "../loading-spinner";
import Appointment from "./appointment";

export default function AppointmentsDisplay() {
  const [appointmentsLoading, setAppointmentsLoading] = useState(false);
  const [appointmentsError, setAppointmentsError] = useState(false);
  const [message, setMessage] = useState("");
  const [futureAppointments, setFutureAppointments] = useState([]);
  const [passedAppointments, setPassedAppointments] = useState([]);

  const getUserAppointmentsAPI = httpsCallable(
    functions,
    "getUserAppointments"
  );

  const cancelAppointmentAPI = httpsCallable(
    functions,
    "cancelAppointment"
  );

  function resetForm() {
    setAppointmentsLoading(false);
    setAppointmentsError(false);
    setMessage("");
  }

  useEffect(() => {
    getUserAppointments();
  }, []);

  async function getUserAppointments() {
    setAppointmentsLoading(true);
    await getUserAppointmentsAPI()
      .then((response) => {
        setAppointmentsLoading(false);
        filterFuturePassedAppointments(response.data);
      })
      .catch((error) => {
        setAppointmentsLoading(false);
        setMessage(
          "An error occurred while retrieving your appointments, please refresh page. Thank you!"
        );
        setAppointmentsError(true);
      });
  }

  function filterFuturePassedAppointments(appts) {
    const currentDateTime = new Date();

    let future = [];
    let passed = [];

    for (let appt of appts) {
      const apptDateTime = new Date(
        appt.date.year,
        appt.date.month,
        appt.date.day,
        appt.time.hour,
        appt.time.minute,
        0
      );

      if (apptDateTime.getTime() > currentDateTime.getTime()) {
        future.push(appt);
      } else {
        passed.push(appt);
      }
    }
    
    setFutureAppointments([...future]);
    setPassedAppointments([...passed]);
  }

  async function cancelAppointment(id) {
    setAppointmentsLoading(true);
    await cancelAppointmentAPI(id)
    .then(response => {
      
      setAppointmentsLoading(false);
      getUserAppointments()
    })
    .catch(error => {
      setAppointmentsLoading(false);
        setMessage(
          "An error occurred while cancelling your appointment, please try again later. Thank you!"
        );
        setAppointmentsError(true);
    })
    

  }

  return (
    <div className="appointments-display-container">
      {appointmentsLoading && (
        <LoadingSpinner message={"Loading your appointments..."} />
      )}
      {appointmentsError && (
        <ErrorPopup closePopup={resetForm} message={message} />
      )}
      {futureAppointments.length === 0 && passedAppointments.length === 0 &&
      <div className="no-appointments-container">
        <span>You don't have any appointments to display yet.</span>
        </div>}
      {futureAppointments.length !== 0 && (
        <div className="future-appointments">
          <span className="appointments-title">FUTURE APPOINTMENTS</span>

          {futureAppointments.map((appt) => {
            return (
              <Appointment
                key={appt.date.timestamp}
                manicure={appt.services.manicure.displayName}
                pedicure={appt.services.pedicure.displayName}
                kidService={appt.services.kidService.displayName}
                menService={appt.services.menService.displayName}
                soakOffService = {appt.services.soakOffService.displayName}
                hour={appt.time.hour}
                minute={appt.time.minute}
                month={appt.date.month}
                day={appt.date.day}
                year={appt.date.year}
                status={appt.status}
                cancelAppointmentFun={cancelAppointment}
                apptId={appt.id}
              />
            );
          })}
        </div>
      )}

      {passedAppointments.length !== 0 && (
        <div className="passed-appointments">
          <span className="appointments-title">FINISHED APPOINTMENTS</span>

          {passedAppointments.map((appt) => {
            return (
              <Appointment
                key={appt.date.timestamp}
                manicure={appt.services.manicure.displayName}
                pedicure={appt.services.pedicure.displayName}
                kidService={appt.services.kidService.displayName}
                menService={appt.services.menService.displayName}
                soakOffService = {appt.services.soakOffService.displayName}
                hour={appt.time.hour}
                minute={appt.time.minute}
                month={appt.date.month}
                day={appt.date.day}
                year={appt.date.year}
                status={appt.status}
                cancelAppointmentFun={cancelAppointment}
                apptId={appt.id}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
