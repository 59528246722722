import HomePage from "./pages/home";
import { Route, Routes, useNavigate } from "react-router-dom";
import CancelAppointmentPage from "./pages/cancel-appointment";
import LoginPage from "./pages/login";
import CreateAccountPage from "./pages/create-account";
import MyProfilePage from "./pages/my-profile";
import BookAppointmentPage from "./pages/book-appointment";
import {
  CopyRightFooter,
  ErrorPopup,
  Footer,
  Header,
  LoadingSpinner,
  SuccessPopup,
} from "./components";
import MySchedulePage from "./pages/my-schedule";
import ForgotPasswordPage from "./pages/forgot-password";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { auth, functions } from "./Firebase";
import userAtom from "./recoil/atoms/user";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { signOut } from "firebase/auth";
import ResendVerificationEmailPage from "./pages/resend-verification-email";
import AppointmentsDisplayPage from "./pages/appointments-display";
import ls from "@livesession/sdk";
import ServicesOffered from "./pages/services-offered";

export default function App() {
  const [getUserLoading, setGetUserLoading] = useState(false);
  const [getUserError, setGetUserError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const setUser = useSetRecoilState(userAtom);
  const userValue = useRecoilValue(userAtom);

  const getUserByIdAPI = httpsCallable(functions, "getUserById");

  useEffect(() => {
    ls.init(process.env.REACT_APP_LIVESESSION_TRACK_ID);
    const authListener = auth.onAuthStateChanged((user) => {
      if (user) {
        getUserById();
      }
    });
    return authListener;
  }, []);

  /**
   * Get user information by calling cloud function.
   */
  async function getUserById() {
    setGetUserLoading(true);

    await getUserByIdAPI()
      .then((response) => {
        setUser(response.data);
        setGetUserLoading(false);

        const firstName = response.data.name.first;
        const lastName = response.data.name.last;
        const email = response.data.contact.communication.email;

        // ls.identify({ name: firstName + " " + lastName, email: email });
        ls.setCustomParams({ name: firstName + " " + lastName, email: email });

        ls.newPageView();

        if (response.data.technician) {
          navigate("/my-schedule");
        } else {
          navigate("/book-appointment");
        }
      })
      .catch((error) => {
        console.log(error);
        setGetUserLoading(false);
        userSignOut();
        setErrorMessage(
          "There was an issue trying to login, please try again later. Thank you!"
        );
        setGetUserError(true);
      });
  }

  /**
   * Sing user out from google authentication.
   */
  async function userSignOut() {
    await signOut(auth)
      .then(() => {
        // Sign-out successful.
        setUser(null);
      })
      .catch((error) => {
        setGetUserLoading(false);
        // An error happened.
      });
  }

  return (
    <div>
      <Header />
      {getUserLoading && (
        <LoadingSpinner message="Getting user information..." />
      )}
      {getUserError && (
        <ErrorPopup
          message={errorMessage}
          closePopup={() => {
            setGetUserError(false);
          }}
        />
      )}
      <Routes>
        <Route path="/success" element={<SuccessPopup />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/cancel-appointment" element={<CancelAppointmentPage />} />
        <Route
          path="/login"
          element={userValue !== null ? <MyProfilePage /> : <LoginPage />}
        />
        <Route
          path="/my-profile"
          element={
            userValue !== null ? (
              <MyProfilePage />
            ) : (
              <ErrorPopup
                message="You must login to access this page. Click dismiss to go to login page."
                closePopup={() => {
                  navigate("/login");
                }}
              />
            )
          }
        />
        <Route
          path="/my-schedule"
          element={
            userValue !== null ? (
              <MySchedulePage />
            ) : (
              <ErrorPopup
                message="You must login to access this page. Click dismiss to go to login page."
                closePopup={() => {
                  navigate("/login");
                }}
              />
            )
          }
        />
        <Route path="/create-account" element={<CreateAccountPage />} />
        <Route
          path="/my-appointments"
          element={
            userValue !== null ? (
              <AppointmentsDisplayPage />
            ) : (
              <ErrorPopup
                message="You must login to access this page. Click dismiss to go to login page."
                closePopup={() => {
                  navigate("/login");
                }}
              />
            )
          }
        />
        <Route path="/book-appointment" element={<BookAppointmentPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route
          path="/resend-verification-email"
          element={<ResendVerificationEmailPage />}
        />
        <Route path="/services-offered" element={<ServicesOffered />} />
      </Routes>
      <Footer />
      <CopyRightFooter />
    </div>
  );
}
