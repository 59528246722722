import { monthsNames } from "../../../utils/variables";

export default function ExistingAppointmentWarning(props) {
  const { appointments, closeFunction } = props;

  return (
    <div className="existing-appt-container">
      <div className="existing-appoint-content">
        <div className="image-container">
          <img
            src={require("../../../assets/images/check-mark.png")}
            alt="check-mark-icon"
          />
        </div>
        <div className="existing-appts">
          <label className="success-text">
            Your time off has been registered successfully!
          </label>
          <br />
          <label className="warning-text">
            ACTION: You must contact the following customers to notify them
            about cancelling their appointments.
          </label>
          {appointments.map((appointment) => {
            return (
              <div
                key={appointment.date.timestamp}
                className="appointment-information"
              >
                <div className="field-column">
                  <div className="title">
                    <label>Customer name:</label>
                  </div>

                  <label>
                    {appointment.customer.name.first}{" "}
                    {appointment.customer.name.last}
                  </label>
                </div>
                <div className="field-column">
                  <div className="title">
                    <label>Phone number:</label>
                  </div>

                  <label>{appointment.customer.communication.phone}</label>
                </div>
                <div className="field-column">
                  <div className="title">
                    <label>Appointment Date:</label>
                  </div>

                  <label>
                    {monthsNames[appointment.date.month] +
                      " " +
                      appointment.date.day +
                      ", " +
                      appointment.date.year}
                  </label>
                </div>
                <div className="field-column">
                  <div className="title">
                    <label>Appointment Time:</label>
                  </div>

                  <label>
                    {appointment.time.hour > 12
                      ? appointment.time.hour - 12
                      : appointment.time.hour}
                    {appointment.time.minute === 0
                      ? ":00"
                      : ":" + appointment.time.minute}
                    {appointment.time.hour >= 12 ? "PM" : "AM"}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
        <div className="existing-appt-buttons">
          <button
            onClick={() => {
              closeFunction();
            }}
          >
            CLOSE
          </button>
        </div>
      </div>
    </div>
  );
}
