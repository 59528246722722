import { useEffect, useState } from "react";
import { monthsNames } from "../../utils/variables";

export default function ConfirmationForm(props) {
  const { appointmentData } = props;

  const [day, setDay] = useState(null);
  const [time, setTime] = useState("");
  const [duration, setDuration] = useState(0);
  const [servicesList, setServicesList] = useState([]);

  useEffect(() => {
    buildServicesDisplay();
  }, []);

  /**
   * Creates the services selected in order to display them.
   */
  function buildServicesDisplay() {
    let durationDisplay = 0;

    let tempServiceList = [];

    if (appointmentData.manicure) {
      let apptServiceList = [];
      apptServiceList.push(appointmentData.manicure);
      tempServiceList.push([...displayServices(apptServiceList)]);
      durationDisplay = appointmentData.manicure.duration;
    }
    if (appointmentData.pedicure) {
      let apptServiceList = [];
      apptServiceList.push(appointmentData.pedicure);
      displayServices(apptServiceList);
      tempServiceList.push(...displayServices(apptServiceList));
      durationDisplay += appointmentData.pedicure.duration;
    }

    if (appointmentData.kidService) {
      let apptServiceList = [];
      apptServiceList.push(appointmentData.kidService);
      tempServiceList.push(...displayServices(apptServiceList));
      durationDisplay += appointmentData.kidService.duration;
    }

    if (appointmentData.menService.length !== 0) {
      tempServiceList.push(...displayServices(appointmentData.menService));
      durationDisplay += sumAddOnsDuration(appointmentData.menService);
    }

    if (appointmentData.soakOffService) {
      let apptServiceList = [];
      apptServiceList.push(appointmentData.soakOffService);
      tempServiceList.push(...displayServices(apptServiceList));
      durationDisplay += appointmentData.soakOffService.duration;
    }

    if (appointmentData.addOnServices.length !== 0) {
      tempServiceList.push(...displayServices(appointmentData.addOnServices));

      durationDisplay += sumAddOnsDuration(appointmentData.addOnServices);
    }

    setDuration(durationDisplay);
    setDay(buildDayDisplay());
    setTime(buildTimeDisplay());
    setServicesList([...tempServiceList]);
  }

  function sumAddOnsDuration(services) {
    let sum = 0;

    for (var service of services) {
      sum += service.duration;
    }

    return sum;
  }

  function displayServices(services) {
    let servicesComponent = [...servicesList];

    for (var service of services) {
      servicesComponent.push(
        <li key={service.fullServiceName}>{service.fullServiceName}</li>
      );
    }

    return servicesComponent;
  }

  /**
   * Creates a day to display.
   * @returns
   */
  function buildDayDisplay() {
    return (
      monthsNames[appointmentData.day.getMonth()] +
      " " +
      appointmentData.day.getDate() +
      ", " +
      appointmentData.day.getFullYear()
    );
  }

  /**
   * Creates a time to display.
   * @returns
   */
  function buildTimeDisplay() {
    const appointmentDate = new Date(appointmentData.time);
    const hours =
      appointmentDate.getHours() > 12
        ? appointmentDate.getHours() - 12
        : appointmentDate.getHours();
    const minutes =
      appointmentDate.getMinutes() === 0 ? "00" : appointmentDate.getMinutes();
    const ampm = appointmentDate.getHours() >= 12 ? " PM" : " AM";

    return hours + ":" + minutes + ampm;
  }

  return (
    <div className="confirmation-form-container">
      <p>
        If you need to change a selection, please use the back button to get to
        the screen you wish to change.
      </p>

      <div className="confirmation-service-container">
        <div className="confirmation-service">
          <div className="service-title">
            <label>Service:</label>
          </div>
          <div className="service-value">
            {/* <label>{services} </label> */}
            {servicesList.length !== 0 &&
              servicesList.map((service) => {
                return service;
              })}
            <ul></ul>
          </div>
        </div>

        {/* <div className="confirmation-service">
          <div className="service-title">
            <label>Price:</label>
          </div>
          <div className="service-value">
            <label>${price}</label>
          </div>
        </div> */}

        <div className="confirmation-service">
          <div className="service-title">
            <label>Appt duration:</label>
          </div>
          <div className="service-value">
            <label>{duration} minutes </label>
          </div>
        </div>

        <div className="confirmation-service">
          <div className="service-title">
            <label>Day:</label>
          </div>
          <div className="service-value">
            <label>{day}</label>
          </div>
        </div>

        <div className="confirmation-service">
          <div className="service-title">
            <label>Time:</label>
          </div>
          <div className="service-value">
            <label>{time}</label>
          </div>
        </div>

        <div className="confirmation-service">
          <div className="service-title">
            <label>
              {appointmentData.cc !== ""
                ? "C.C. last four:"
                : "Zelle Confirmation Number:"}
            </label>
          </div>
          <div className="service-value">
            <label>
              {appointmentData.cc !== ""
                ? appointmentData.cc
                : appointmentData.zelleConfirmation}{" "}
            </label>
          </div>
        </div>

        <div className="confirmation-service">
          <div className="service-title">
            <label>Nail technician:</label>
          </div>
          <div className="service-value">
            <label>Lis Mora </label>
          </div>
        </div>
      </div>
    </div>
  );
}
