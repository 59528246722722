import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import userAtom from "../../../recoil/atoms/user";
import LoginButton from "../../login-button/login-button";

export default function NavButtonsMenu(props) {
  const { cssShowNavButtons = false, callBackParent } = props;

  const navigate = useNavigate();

  const user = useRecoilValue(userAtom);

  /**
   * Handles closing the nav bar for mobile.
   * @param {path} path
   */
  function closeMobileNavBar(path) {
    callBackParent(!cssShowNavButtons);
    navigate(path);
  }

  return (
    <div
      className={`nav-buttons-menu-container ${
        cssShowNavButtons ? "show-menu" : ""
      }`}
    >
      <li>
        <button
          className="header-buttons"
          title="Home"
          onClick={() => {
            closeMobileNavBar("/");
          }}
        >
          Home
        </button>
      </li>
      <li>
        <button
          className="header-buttons"
          title="Services"
          onClick={() => {
            closeMobileNavBar("/services-offered");
          }}
        >
          Services
        </button>
      </li>
      <li>
        <a href="http://lisnailsandspa.booksy.com/g/">
          <button
            className="header-buttons"
            title="Book Appointment"
            // onClick={() => {
            //   closeMobileNavBar("/book-appointment");
            // }}
          >
            Book Appointment
          </button>
        </a>
      </li>
      {/* {user === null && (
        <li>
          <button
            className="header-buttons"
            title="Cancel Appointment"
            onClick={() => {
              closeMobileNavBar("/cancel-appointment");
            }}
          >
            Cancel Appointment
          </button>
        </li>
      )} */}

      {user !== null && (
        <li>
          <button
            className="header-buttons"
            title="My Profile"
            onClick={() => {
              closeMobileNavBar("/my-profile");
            }}
          >
            My Profile
          </button>
        </li>
      )}
      {user !== null && user.technician === true && (
        <li>
          <button
            className="header-buttons"
            title="My Schedule"
            onClick={() => {
              closeMobileNavBar("/my-schedule");
            }}
          >
            My Schedule
          </button>
        </li>
      )}
      {user !== null && (
        <li>
          <button
            className="header-buttons"
            title="My Appointments"
            onClick={() => {
              closeMobileNavBar("/my-appointments");
            }}
          >
            My Appointments
          </button>
        </li>
      )}

      {/* <div
        className="login-button-container"
        onClick={() => {
          closeMobileNavBar("/login");
        }}
      >
        <LoginButton />
      </div> */}
    </div>
  );
}
