export default function HamburgerMenu(props) {
  const { cssShowX = false } = props;

  return (
    <div className={`hamburger-lines ${cssShowX ? "show-close" : ""}`}>
      <div className="line line1" />
      <div className="line line2" />
      <div className="line line3" />
    </div>
  );
}
