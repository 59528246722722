import { useState } from "react";
import { buildOptions } from "../../utils/common-functions";
import { services } from "../../utils/variables";

export default function PedicureServices(props) {
  const { callParentFunction } = props;

  const [pedicure, setPedicure] = useState("default");
  const [pedicureRegularPolishChange, setPedicureRegularPolishChange] =
    useState("default");
  const [pedicureGelPolishChange, setPedicureGelPolishChange] =
    useState("default");

  function updatePedicureSelection(service, value) {
    if (service === "pedicure") {
      setPedicure(value);
      setPedicureRegularPolishChange("default");
      setPedicureGelPolishChange("default");
    } else if (service === "regular-polish-change") {
      setPedicure("default");
      setPedicureRegularPolishChange(value);
      setPedicureGelPolishChange("default");
    } else if (service === "gel-polish-change") {
      setPedicure("default");
      setPedicureRegularPolishChange("default");
      setPedicureGelPolishChange(value);
    }

    updateSelection(value);
  }

  /**
   * Assigns the selected value.
   * @param {value} value
   */
  function updateSelection(value) {
    value !== "default"
      ? callParentFunction(JSON.parse(value))
      : callParentFunction("");
  }

  return (
    <div className="pedicure-services-container">
      <div className="pedicures">
        <label>Pedicures</label>
        <select
          name="pedicure"
          value={pedicure}
          onChange={(event) =>
            updatePedicureSelection("pedicure", event.target.value)
          }
        >
          <option value="default">Select one...</option>
          {buildOptions(services.pedicureServices.pedicures)}
        </select>
      </div>

      <div className="pedicures">
        <label>Regular Polish Change</label>
        <select
          name="regular polish change"
          value={pedicureRegularPolishChange}
          onChange={(event) =>
            updatePedicureSelection("regular-polish-change", event.target.value)
          }
        >
          <option value="default">Select one...</option>
          {buildOptions(services.pedicureServices.polishChange.regularPolish)}
        </select>
      </div>

      <div className="pedicures">
        <label>Gel Polish Change</label>
        <select
          name="gel polish change"
          value={pedicureGelPolishChange}
          onChange={(event) =>
            updatePedicureSelection("gel-polish-change", event.target.value)
          }
        >
          <option value="default">Select one...</option>
          {buildOptions(services.pedicureServices.polishChange.gelPolish)}
        </select>
      </div>
    </div>
  );
}
