import { useEffect, useState } from "react";
import { monthsNames } from "../../../utils/variables";

export default function Appointment(props) {
  const {
    manicure,
    pedicure,
    kidService,
    menService,
    hour,
    minute,
    status,
    month,
    day,
    year,
    cancelAppointmentFun,
    apptId,
    soakOffService
  } = props;

  const [services, setServices] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    buildDisplayData();
  }, []);

  function buildDisplayData() {
    let displayServices = "";
    let ampm = "";
    let hourDisplay = "";
    let displayMinute = minute === 0 ? "00" : minute;

    if (manicure && pedicure && kidService && menService) {
      displayServices =
        manicure + "," + pedicure + "," + kidService + "and" + menService;
    } else if (manicure || pedicure || kidService || menService || soakOffService) {
      displayServices = manicure ? manicure + ". " : "";
      displayServices += pedicure ? pedicure + ". " : "";
      displayServices += kidService ? kidService + ". " : "";
      displayServices += menService ? menService + ". " : "";
      displayServices += soakOffService ? soakOffService + ". " : "";
    }

    if (hour > 12) {
      ampm = " PM";
      hourDisplay = hour - 12;
    } else {
      ampm = " AM";
      hourDisplay = hour;
    }

    setServices(displayServices);
    setTime(hourDisplay + ":" + displayMinute + ampm);
  }
  return (
    <div className="appointment-container">
      <div className="appointment-details-container">
        <div className="appointment-details">
          <span className="small-font">status: {status}</span>
          <span className="bold">{services}</span>
          <span className="small-font">with Lis Mora</span>
          {status === "not checked-in" && (
            <button className="appointment-cancel"
            onClick={()=>{cancelAppointmentFun(apptId)}}>cancel</button>
          )}
        </div>
      </div>
      <div className="appointment-date">
        <div className="date-details">
          <span className="">{monthsNames[month]}</span>
          <span className="big-font">{day}</span>
          <span>{year}</span>
          <span>{time}</span>
        </div>
      </div>
    </div>
  );
}
