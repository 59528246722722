import { Calendar } from "../../components";

export default function MySchedulePage() {
  return (
    <div className="my-schedule-container">
      <div className="schedule-title">
        <h3>My Schedule</h3>
        <p>
          You can view all the current appointments here. You will not be able
          to make any appointments from this page. In order to book an
          appointment, go to Book Appointment tab.
        </p>
      </div>
      <Calendar />
    </div>
  );
}
