export default function SuccessPopup(props) {
  const { message, closePopup } = props;

  return (
    <div className="success-popup-container">
      <div className="success-content">
        <div className="circle-container">
          <img
            src={require("../../assets/images/check-mark.png")}
            alt="check-mark-icon"
          />
        </div>
        <div className="success-text">
          <h3>Success!</h3>
          <p>{message}</p>
        </div>
        <div className="success-button-container">
          <button onClick={()=>{closePopup()}}>OK</button>
        </div>
      </div>
    </div>
  );
}
