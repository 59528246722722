export default function ZelleTransaction(props) {
  const { updateZelleConfirmation } = props;
  return (
    <div className="zelle-container">
      <label className="zelle-intro">
        This deposit will be deducted
        from your visit's price at the end of your appointment.
      </label>
      <div className="instructions-container">
        <ol type="1">
          <li>Login to your Zelle</li>
          <li>Send $20 deposit to 317-793-5445</li>
          <li>Copy the confirmation number</li>
          <li>Enter confirmation number in box below</li>
        </ol>
        <input
          onChange={(event) => {
            updateZelleConfirmation(event.target.value);
          }}
          type="text"
          className="zelle-confimation"
        />
      </div>
    </div>
  );
}
