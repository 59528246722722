import { useState } from "react";
import ErrorPopup from "../error-popup";
import LoadingSpinner from "../loading-spinner";
import SuccessPopup from "../success-popup";
import { validateEmail } from "../../utils/common-functions";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../Firebase";

export default function ResendVerificationEmailForm() {
  const [email, setEmail] = useState("");
  const [resendVerificationEmailLoading, setResendVerificationEmailLoading] =
    useState(false);
  const [resendVerificationEmailError, setResendVerificationEmailError] =
    useState(false);
  const [message, setMessage] = useState("");
  const [resendVerificationEmailSuccess, setResendVerificationEmailSuccess] =
    useState(false);
  const [emailError, setEmailError] = useState(false);

  const navigate = useNavigate();

  const resendVerificationEmailAPI = httpsCallable(
    functions,
    "resendVerificationEmail"
  );

  function resetForm() {
    setResendVerificationEmailLoading(false);
    setResendVerificationEmailError(false);
    setMessage("");
    setResendVerificationEmailSuccess(false);
    setEmail("");
  }

  /**
   * Resend the verification email only when the user's email hasn't been
   * verified and the email is valid.
   */
  async function resendVerificationEmail() {
    if (validateEmail(email)) {
      setResendVerificationEmailLoading(true);
      await resendVerificationEmailAPI({ userEmail: email })
        .then((response) => {
          setResendVerificationEmailLoading(false);
          if (response.data === "Email sent") {
            setMessage("The verification email has been sent!");
          }
          if (response.data === "Email already verified") {
            setMessage(
              "The email provided has been verified. You can now login."
            );
          }
          setResendVerificationEmailSuccess(true);
        })
        .catch((error) => {
          setResendVerificationEmailLoading(false);
          if (error.code === "functions/not-found") {
            setMessage(error.message + " Email: " + email);
          } else {
            setMessage(
              "An error occurred while trying to send the verification email. Please try again later."
            );
          }

          setResendVerificationEmailError(true);
        });
    } else {
      setEmailError(true);
    }
  }

  /**
   * Sets the email estate and checks if the email is valid.
   * @param {value} value
   */
  function updateEmail(value) {
    setEmail(value);

    if (validateEmail(value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  }

  return (
    <div className="verification-email-form-container">
      {resendVerificationEmailLoading && (
        <LoadingSpinner message="Sending verification email..." />
      )}

      {resendVerificationEmailError && (
        <ErrorPopup message={message} closePopup={resetForm} />
      )}

      {resendVerificationEmailSuccess && (
        <SuccessPopup message={message} closePopup={resetForm} />
      )}
      <div className="forgot-pass-card">
        <div className="resend-verification-title">
          <label>
            Please, verify your email. <span style={{color: "#EB5757"}}>Check your spam or promotion folder.</span>
          </label>

          <div className="email-icon-container">
            <img
              src={require("../../assets/images/email-icon.png")}
              alt="email-logo"
            />
          </div>
        </div>
        <div className="title-description">
          <p>
            {" "}
            If you didn't receive it, you can resend it here. Enter your email
            and we'll send you another link.
          </p>
        </div>
        <div className="form-container">
          <input
            type="text"
            placeholder="Your email here..."
            value={email}
            onChange={(event) => {
              updateEmail(event.target.value);
            }}
          ></input>
          {emailError && <label className="invalid-email">Invalid email</label>}

          <button
            onClick={() => {
              resendVerificationEmail();
            }}
          >
            Submit
          </button>
          <div className="back-link-container">
            <img
              src={require("../../assets/images/back-icon.png")}
              alt="back-icon"
            />
            <label
              className="back-link"
              onClick={() => {
                navigate("/login");
              }}
            >
              Back to Login
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
