export default function ContentCard(props) {
  const { title, paragraph, imageType, reverse, displayDots } = props;

  return (
    <div
      className={`content-card-container ${
        reverse ? "reverse-row" : "no-reverse"
      }`}
    >
      <div className="text-container">
        <div className="content-card-title">{title}</div>
        <p className="content-card-paragraph">{paragraph}</p>

        <div className="dots-container bg-screen-dots ">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
      </div>

      {imageType === "safety" && (
        <div className="safety-list-container">
          <ul>
            <li>
              <span className="circle-checkmark">✔</span> We have sneeze guards
              at the service desk for our hands to keep our clients and nail
              technicians safe.
            </li>
            <li>
              <span className="circle-checkmark">✔</span> Hand sanitizers will
              be available throughout the room.
            </li>
            <li>
              <span className="circle-checkmark">✔</span> We keep all areas of
              the salon clean and disinfected.
            </li>
            <li>
              <span className="circle-checkmark">✔</span> To ensure the safety
              of our customers and employees, please limit the number of
              personal guests to 1 person
            </li>
            <li>
              <span className="circle-checkmark">✔</span> The use of a mask is
              under the responsibility of each client.
            </li>
          </ul>
        </div>
      )}

      <div className="content-card-image-container">
        {imageType === "foot" && (
          <img
            className="content-card-image"
            src={require("../../assets/images/washing-foot.png")}
            alt="company-logo"
          />
        )}

        {imageType === "hands" && (
          <img
            className="content-card-image"
            src={require("../../assets/images/hands.png")}
            alt="company-logo"
          />
        )}
      </div>
      <div
        className={`dots-container sm-screen-dots ${
          displayDots ? "" : "no-display-dots"
        }`}
      >
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
      </div>
    </div>
  );
}
