import { httpsCallable } from "firebase/functions";
import { React, useState } from "react";
import { functions } from "../../Firebase";
import { monthsNames } from "../../utils/variables";
import ErrorPopup from "../error-popup";
import LoadingSpinner from "../loading-spinner";
import SuccessPopup from "../success-popup";

export default function CancelAppointmentForm() {
  const [confirmationNum, setConfirmationNum] = useState("");
  const [confirmationInputError, setConfirmationInputError] = useState(false);
  const [cancelAppointmentLoading, setCancelAppointmentLoading] =
    useState(false);
  const [cancelAppointmentError, setCancelAppointmentError] = useState(false);
  const [cancelAppointmentSuccess, setCancelAppointmentSuccess] =
    useState(false);
  const [message, setMessage] = useState("");

  const cancelAppointmentAPI = httpsCallable(functions, "cancelAppointment");

  /**
   * Attempts to cancel appointment by calling cloud function.
   */
  async function cancelAppointment() {
    setCancelAppointmentLoading(true);
    await cancelAppointmentAPI(confirmationNum)
      .then((response) => {
        setCancelAppointmentLoading(false);
        setMessage(buildSuccessMessage(response.data))
        setCancelAppointmentSuccess(true)
      })
      .catch((error) => {
        handleError(error);
  
      });
  }

  function buildSuccessMessage(appointment) {

    const ampm = appointment.time.hour >= 12 ? "PM" : "AM"
    const minutes = appointment.time.minute === 0 ? "00" : appointment.time.minute

    return "Your appoint on " + monthsNames[appointment.date.month]
    + " " + appointment.date.day + ", " + appointment.date.year + " at "
    + appointment.time.hour + ":" + minutes + " " + ampm 
    + " has been cancelled. Thank you!"
  }

  function handleError(error) {
    if (error.code === "functions/invalid-argument") {
      setMessage(
        "The confirmation number you have entered is invalid. Please try again with a valid confirmation number."
      );
    } else if (error.code === "functions/not-found") {
      setMessage(
        "There are no existing appointments with given confirmation number. Please, try again with a different confirmation number."
      );
    } else if (error.code === "functions/cancelled") {
      setMessage("Appointment cannot be cancel because the status is either checked in or cancelled.")
    } else {
      setMessage(
        "An unknown error has occurred while cancelling your appointment, please try again later. Thank you!"
      );
    }

    setCancelAppointmentLoading(false);
    setCancelAppointmentError(true);
  }

  /**
   * Validates if the confirmation number is greater than 5.
   * If so, then it will update the state.
   * @param {number} number
   */
  function updateConfirmationInput(number) {
    if (number.length >= 5) {
      setConfirmationInputError(false);
      setConfirmationNum(number);
    } else {
      setConfirmationInputError(true);
      setConfirmationNum(number);
    }
  }

  /**
   * Closes error popup and resets the confirmation number state
   */
  function handleCloseErrorPopup() {
    setCancelAppointmentError(false);
    setConfirmationNum("");
  }

  /**
   * Closes success popup and resets the confirmation number state
   */
  function handleCloseSuccessPopup() {
    setCancelAppointmentSuccess(false);
    setConfirmationNum("");
  }

  return (
    <div className="cancel-appt-form-container">
      {cancelAppointmentLoading && (
        <LoadingSpinner message="Cancelling your appointment..." />
      )}
      {cancelAppointmentError && (
        <ErrorPopup
          closePopup={() => {
            handleCloseErrorPopup();
          }}
          message={message}
        />
      )}
      {cancelAppointmentSuccess && (
        <SuccessPopup
          closePopup={() => {
            handleCloseSuccessPopup();
          }}
          message={message}
        />
      )}

      <div className="text-container">
        <h3>Cancel Appointment Form</h3>
        <p>
          After cancelling your appointment, your appointment will no longer
          exists. You will have to make a new appointment through the "Book
          Appointment" page in order to reschedule a new appointment.
        </p>
      </div>

      <div className="cancel-appt-fields-container">
        <div className="confirmation-number-container">
          <label>Confirmation Number:</label>
          <input
            type="text"
            id="confirmation-number"
            name="confirmation number"
            placeholder="Enter confirmation number"
            value={confirmationNum}
            onChange={(event) => {
              updateConfirmationInput(event.target.value);
            }}
          ></input>
        </div>
        {confirmationInputError && (
          <div className="confirmation-error-container">
            <label className="confirmation-error">
              Please, enter a valid confirmation number.
            </label>
          </div>
        )}
      </div>
      <div className="cancel-button-container">
        <button
          disabled={confirmationNum.length < 20}
          onClick={() => cancelAppointment()}
        >
          Submit Cancellation
        </button>
      </div>
    </div>
  );
}
