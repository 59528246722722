import { useRecoilValue } from "recoil";
import { BookAppointmentForm } from "../../components";
import MustLoginFirst from "../../components/must-login-first";
import userAtom from "../../recoil/atoms/user";

export default function BookAppointmentPage() {
  const user = useRecoilValue(userAtom);

  return (
    <div>
      {/* <PageIntro
        miniTitle={"Ready for a new appointment?"}
        title={"Treat Yourself to a Relaxing Day at Lis Nails & Spa"}
        messageContent={
          "All appointments are per individual. If you are trying to schedule an appointment for someone else, please login with a different account."
        }
        pageName="book"
        buttonsVisible={false}
      /> */}
      {user === null ? <MustLoginFirst /> : <BookAppointmentForm />}
    </div>
  );
}
