import { ForgotPasswordForm, SplashBox } from "../../components";

export default function ForgotPasswordPage() {
  return (
    <div className="forgot-password-page-container">
      <ForgotPasswordForm />
      <SplashBox />
    </div>
  );
}
