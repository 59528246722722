import { useState } from "react";
import { useNavigate } from "react-router-dom";
import HamburgerMenu from "./hamburger-menu-icon";
import NavButtonsMenu from "./nav-buttons-menu";

export default function Header() {
  const [cssApplyAnimation, setCssApplyAnimation] = useState(false);

  const navigate = useNavigate();

  return (
    <div className="header">
      <div
        className="image-container"
        onClick={() => {
          navigate("/");
        }}
      >
        <img
          src={require("../../assets/images/lis-spa-logo.png")}
          alt="company-logo"
        />
      </div>

      <NavButtonsMenu
        cssShowNavButtons={cssApplyAnimation}
        callBackParent={setCssApplyAnimation}
      />
      <div
        onClick={() => {
          setCssApplyAnimation(!cssApplyAnimation);
        }}
      >
        <HamburgerMenu cssShowX={cssApplyAnimation} />
      </div>
    </div>
  );
}
