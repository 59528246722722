import { useState } from "react";
import { buildOptions } from "../../utils/common-functions";
import { services } from "../../utils/variables";

export default function AddOnServices(props) {
  const { callParentFunction } = props;

  const [addOnManicure, setAddOnManicure] = useState("default");
  const [manicureValue, setManicureValue] = useState("");
  const [addOnPedicure, setAddOnPedicure] = useState("default");
  const [pedicureValue, setPedicureValue] = useState("");
  const [addOnSelected, setAddOnSelected] = useState([]);

  function updateSelection(service, value) {
    if (service === "manicure") {
      setAddOnManicure(value);
      setManicureValue(JSON.parse(value));
    } else if (service === "pedicure") {
      setAddOnPedicure(value);
      setPedicureValue(JSON.parse(value));
    }
  }

  function addAddOn(value) {
    if (value) {
      const temp = [...addOnSelected];
      temp.push(value);
      setAddOnSelected([...temp]);
      setAddOnManicure("default");
      setAddOnPedicure("default");
      setManicureValue("");
      setPedicureValue("");
      callParentFunction([...temp]);
    }
  }

  function removeAddOn(index) {
    const temp = [...addOnSelected];
    temp.splice(index, 1);
    setAddOnSelected([...temp]);
    callParentFunction([...temp]);
  }

  return (
    <div className="add-on-services-container">
      <div className="add-on-services">
        <div className="add-on-select">
          <div>
            <label>Nails</label>
          </div>

          <select
            name="nails services"
            value={addOnManicure}
            onChange={(event) =>
              updateSelection("manicure", event.target.value)
            }
          >
            <option value="default">Select one...</option>
            {buildOptions(services.addOnServices.nail)}
          </select>
        </div>

        <div className="add-on-button-container">
          <button
            className="add-on-add-button"
            onClick={() => {
              addAddOn(manicureValue);
            }}
          >
            add
          </button>
        </div>
      </div>

      <div className="add-on-services">
        <div className="add-on-select">
          <div>
            <label>Pedicure</label>
          </div>

          <select
            name="pedicure services"
            value={addOnPedicure}
            onChange={(event) =>
              updateSelection("pedicure", event.target.value)
            }
          >
            <option value="default">Select one...</option>
            {buildOptions(services.addOnServices.pedicure)}
          </select>
        </div>

        <div className="add-on-button-container">
          <button
            className="add-on-add-button"
            onClick={() => {
              addAddOn(pedicureValue);
            }}
          >
            add
          </button>
        </div>
      </div>
      {addOnSelected.length > 0 && (
        <div className="added-list-container">
          <span>Add Ons selected</span>
          <table>
            {addOnSelected.map((addOn, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <th>{addOn.displayName}</th>
                    <th>
                      <img
                        onClick={() => {
                          removeAddOn(index);
                        }}
                        className="footer-logo-img"
                        src={require("../../assets/images/trash-can.png")}
                        alt="trash-can-icon"
                      />
                    </th>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      )}
    </div>
  );
}
