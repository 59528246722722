import { buildOptions } from "../../utils/common-functions";
import { services } from "../../utils/variables";

export default function SoakOffServices(props) {
  const { callParentFunction } = props;

  /**
   * Assigns the selected value.
   * @param {value} value
   */
  function updateSelection(value) {
    value !== "default"
      ? callParentFunction(JSON.parse(value))
      : callParentFunction("");
  }

  return (
    <div className="soak-off-services-container">
      <label>Soak Off Services</label>
      <select
        name="soak off services"
        onChange={(event) => updateSelection(event.target.value)}
      >
        <option value="default">Select one...</option>
        {buildOptions(services.soakOffServices)}
      </select>
    </div>
  );
}
