export default function TimeSelection(props) {
  const { callParentFunction, times } = props;

  function updateTimeSelection(value) {
    callParentFunction(value);
  }

  /**
   * Creates a time to display based on the time object received.
   * @param {time} time
   * @returns
   */
  function buildTimeDisplayName(time) {
    const minutes = time.getMinutes() === 0 ? "00" : time.getMinutes();
    const hours = time.getHours() > 12 ? time.getHours() - 12 : time.getHours();
    const ampm = time.getHours() >= 12 ? " PM" : " AM";

    return hours + ":" + minutes + ampm;
  }

  return (
    <div className="time-selection-container">
      <div className="time-title">
        <label>Available times:</label>
      </div>

      <select
        onChange={(event) => {
          updateTimeSelection(event.target.value);
        }}
      >
        <option value="default">Select one...</option>

        {times &&
          times.map((time, index) => {
            return (
              <option key={index} value={time}>
                {buildTimeDisplayName(time)}
              </option>
            );
          })}
      </select>
    </div>
  );
}
