import StepperCustom from "../stepper-custom";

export default function BookAppointmentForm() {
  return (
    <div className="book-appt-form-container">
      <div className="title-container">
        <h3>Book Appointment</h3>
        <p>
          All appointments provide a 8-days guarantee period on gel, dip
          powder, & artificial services in which you may return to the salon to
          have repairs done free of charge based on same color.
        </p>
      </div>

      <StepperCustom />
    </div>
  );
}
