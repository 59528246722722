export default function ErrorPopup(props) {
  const { message, closePopup } = props;

  return (
    <div className="error-popup-container">
      <div className="popup-content">
        <div className="error-icon-container">
          <img
            src={require("../../assets/images/error-icon.png")}
            alt="error-icon"
          />
        </div>
        <div className="popup-title">
          <h3>Oh snap!</h3>
          <p>{message}</p>
        </div>
        <div className="error-button-container">
            <button className="error-button-dismiss"
            onClick={()=>{closePopup()}}>DISMISS</button>
        </div>
      </div>
    </div>
  );
}
