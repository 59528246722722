import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { functions } from "../../Firebase";
import appointmentsAtom from "../../recoil/atoms/appointments";
import dateRangesAtom from "../../recoil/atoms/date-ranges";
import AppointmentDetailsCard from "../appointment-details-card";
import ErrorPopup from "../error-popup";
import LoadingSpinner from "../loading-spinner";
import TimeOffForm from "../time-off-form";

export default function CalendarComponent() {
  const [getAppointmentsLoading, setGetAppointmentsLoading] = useState(false);
  const [getAppointmentsError, setGetAppointmentsError] = useState(false);
  const [message, setMessage] = useState("");
  const [openAppointmentDetails, setOpenAppointmentDetails] = useState(false);
  const [appointmentDetailsPopup, setAppointmentDetailsPopup] = useState({});
  const [timeOFfFormVisible, setTimeOFfFormVisible] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [appointmentsVO, setAppointmentsVO] = useState([]);

  const getAppointmentsByDateAPI = httpsCallable(
    functions,
    "getAppointmentByDateRage"
  );

  const setAppointments = useSetRecoilState(appointmentsAtom);
  const appointmentsValue = useRecoilValue(appointmentsAtom);
  const setDateRanges = useSetRecoilState(dateRangesAtom);
  const dateRangesValue = useRecoilValue(dateRangesAtom);

  /**
   * Fetch appointments every time the date range changes.
   */
  useEffect(() => {
    if (dateRange.start) {
      if (appointmentsValue.length === 0) {
        getAppointmentByDate(appointmentsValue, dateRangesValue);
      } else if (!checkIfDateRangeExists()) {
        getAppointmentByDate(appointmentsValue, dateRangesValue);
      } else {
        buildEvents([...appointmentsValue]);
      }
    }
  }, [dateRange]);

  /**
   * Searches in the date ranges list if the current
   * date range is in the list.
   * @returns
   */
  function checkIfDateRangeExists() {
    let includes = false;

    for (let date of dateRangesValue) {
      if (
        date.startStr === dateRange.startStr &&
        date.endStr === dateRange.endStr
      ) {
        includes = true;
        break;
      }
    }

    return includes;
  }

  function resetForm() {
    setGetAppointmentsLoading(false);
    setGetAppointmentsError(false);
    setMessage("");
  }

  /**
   * Before getting the appointments, check if already exists.
   */
  async function getAppointmentByDate(appts, ranges) {
    if (!checkIfDateRangeExists()) {
      await getAppointments(appts, ranges);
    }
  }

  /**
   * Call cloud function to retrieve all appointments in the date range.
   * @param {appts} appts
   * @param {ranges} ranges
   */
  async function getAppointments(appts, ranges) {
    setGetAppointmentsLoading(true);
    await getAppointmentsByDateAPI(buildRequest())
      .then((response) => {
        const tempAppointments = [...appts];
        tempAppointments.push(...response.data);
        setAppointments([...tempAppointments]);
        setGetAppointmentsLoading(false);
        buildEvents(tempAppointments);
        let tempDateList = [...ranges];
        tempDateList.push(dateRange);
        setDateRanges([...tempDateList]);
      })
      .catch((error) => {
        setGetAppointmentsLoading(false);
        setMessage(
          "An error occurred while loading appointments, please click Refresh Calendar to try again."
        );
        setGetAppointmentsError(true);
      });
  }

  /**
   * Creates the request to call cloud function to retrieve
   * all appointments in the date range.
   * @returns
   */
  function buildRequest() {
    const startDate = new Date(dateRange.startStr);
    const endDate = new Date(dateRange.endStr);
    return {
      startDay: startDate.getDate(),
      startMonth: startDate.getMonth(),
      startYear: startDate.getFullYear(),
      endDay: endDate.getDate(),
      endMonth: endDate.getMonth(),
      endYear: endDate.getFullYear(),
    };
  }

  /**
   * Builds the JSON array to display in <FullCalendar /> . Each
   * json object represents one appointment. Events is what is called in
   * FullCalendar component. Events and an appointment are equivalent.
   */
  function buildEvents(appointmentList) {
    const computeDate = new Date();
    const offset = computeDate.getTimezoneOffset();

    let calendarEvents = [];
    for (var o in appointmentList) {
      let currentAppointment = appointmentList[o];

      let appointmentTime = currentAppointment.time;
      let appoinmentDate = currentAppointment.date;

      let startTime = new Date(
        appoinmentDate.year,
        appoinmentDate.month,
        appoinmentDate.day,
        appointmentTime.hour,
        appointmentTime.minute - offset,
        0,
        0
      );

      let endTime = new Date(
        appoinmentDate.year,
        appoinmentDate.month,
        appoinmentDate.day,
        appointmentTime.hour,
        appointmentTime.minute + currentAppointment.duration - offset,
        0,
        0
      );
      calendarEvents.push({
        title:
          currentAppointment.customer.name.first +
          " " +
          currentAppointment.customer.name.last,
        start: startTime.toISOString().split(".")[0],
        end: endTime.toISOString().split(".")[0],
        allDay: false,
      });
    }

    setAppointmentsVO([...calendarEvents]);
  }

  /**
   * Find the appointment information from the appointment selected in the
   * calendar.
   * @param {Appointment Selected} appointment
   */
  function findAppointment(appointment) {
    for (var o in appointmentsValue) {
      let currentAppointment = appointmentsValue[o];

      let fullName =
        currentAppointment.customer.name.first +
        " " +
        currentAppointment.customer.name.last;

      let timeToFind = new Date(appointment.start);

      let appointmentTime = currentAppointment.time;
      let appoinmentDate = currentAppointment.date;
      let currentAppointmentTime = new Date(
        appoinmentDate.year,
        appoinmentDate.month,
        appoinmentDate.day,
        appointmentTime.hour,
        appointmentTime.minute,
        0,
        0
      );

      if (
        fullName.toLowerCase() === appointment.title.toLowerCase() &&
        timeToFind.getTime() === currentAppointmentTime.getTime()
      ) {
        setAppointmentDetailsPopup(currentAppointment);
      }
    }
  }

  /**
   * Removes the appointment from the list to display.
   * @param {cancelledAppointment} cancelledAppointment
   */
  function cancelAppointment(cancelledAppointment) {
    let index = appointmentsValue.indexOf(cancelledAppointment);
    let tempAppointments = [...appointmentsValue];
    if (index > -1) {
      // only splice array when item is found
      tempAppointments.splice(index, 1); // 2nd parameter means remove one item only
    }
    setAppointments([...tempAppointments]);
    buildEvents(tempAppointments);
  }

  /**
   * Updates the appointment in the list with the new status.
   * @param {checkedInAppointment} checkedInAppointment
   * @param {status} status
   */
  function updateCheckInStatus(checkedInAppointment, status) {
    var tempAppointment = { ...checkedInAppointment };
    tempAppointment.status = status;
    let index = appointmentsValue.indexOf(checkedInAppointment);
    let tempAppointments = [...appointmentsValue];
    if (index > -1) {
      // only splice array when item is found

      tempAppointments.splice(index, 1); // 2nd parameter means remove one item only
      tempAppointments.push(tempAppointment);
    }

    setAppointments([...tempAppointments]);
    buildEvents(tempAppointments);
  }

  /**
   * Closes appointment details card popup.
   */
  function updateCloseAppointmentDetails() {
    setOpenAppointmentDetails(false);
  }

  function openAppointmentDetailsPopup(event) {
    findAppointment(event);
    setOpenAppointmentDetails(true);
  }

  function updateDateRange(range) {
    if (dateRange.startStr !== range.startStr) {
      setDateRange(range);
    }
  }

  async function handleRefresh() {
    getAppointments([], []);
  }

  return (
    <div className="calendar-container">
      {openAppointmentDetails && (
        <AppointmentDetailsCard
          appointment={appointmentDetailsPopup}
          closePopup={updateCloseAppointmentDetails}
          cancelAppointment={cancelAppointment}
          checkInAppointment={updateCheckInStatus}
        />
      )}
      {getAppointmentsLoading && (
        <LoadingSpinner message="Searching appointments..." />
      )}
      {getAppointmentsError && (
        <ErrorPopup message={message} closePopup={resetForm} />
      )}
      
      <FullCalendar
        eventColor="#622b62"
        plugins={[timeGridPlugin]}
        slotMinTime="09:00:00"
        slotMaxTime="20:00:00"
        firstDay={1} // Monday
        allDaySlot={false}
        initialView="timeGridWeek"
        height="auto"
        hiddenDays={[0]} // Sunday
        editable={true} // makes cursor pointer in event
        eventSources={[
          {
            events: appointmentsVO,
            color: "#622b62",
          },
        ]}
        eventClick={(arg) => {
          openAppointmentDetailsPopup(arg.event);
        }}
        datesSet={(dateInfo) => {
          updateDateRange(dateInfo);
        }}
      />

      <div className="calendar-buttons">
        <button
          className="refresh-button"
          onClick={() => {
            handleRefresh();
          }}
        >
          REFRESH CALENDAR
        </button>

        <button
          className="refresh-button"
          onClick={() => {
            setTimeOFfFormVisible(!timeOFfFormVisible);
          }}
        >
          ADD TIME OFF
        </button>
      </div>

      {timeOFfFormVisible && (
        <TimeOffForm
          hideForm={() => {
            setTimeOFfFormVisible(!timeOFfFormVisible);
          }}
          updateCalendar={getAppointments}
        />
      )}
    </div>
  );
}
