import React, { useState } from "react";

import AppointmentDetails from "./appointment-details";
import CancelAppointmentDetailsCard from "./cancel-appointment";
import CheckIn from "./check-in";

export default function AppointmentDetailsCard(props) {
  const { appointment, closePopup, cancelAppointment, checkInAppointment } =
    props;

  const [activeCard, setActiveCard] = useState("appointment-details");

  return (
    <div className="appointment-details-pop-up">
      <div className="appt-details-card-container">
        <div className="card-content-container">
          <div className="card-title">
            {activeCard === "appointment-details" && (
              <h3>Appointment Details</h3>
            )}
            {activeCard === "cancel-appointment" && <h3>Cancel Appointment</h3>}
            {activeCard === "check-in" && <h3>Check In</h3>}
          </div>

          {activeCard === "appointment-details" && (
            <AppointmentDetails appointment={appointment} />
          )}
          {activeCard === "cancel-appointment" && (
            <CancelAppointmentDetailsCard
              closePopup={closePopup}
              appointment={appointment}
              cancelAppointmentParentFunction={cancelAppointment}
            />
          )}
          {activeCard === "check-in" && (
            <CheckIn
              appointment={appointment}
              closePopup={closePopup}
              checkInAppointmentParent={checkInAppointment}
            />
          )}

          {(activeCard === "cancel-appointment" ||
            activeCard === "check-in") && (
            <div className="back-link-container">
              <img
                src={require("../../assets/images/back-icon.png")}
                alt="back-icon"
              />
              <label
                className="back-link"
                onClick={() => {
                  setActiveCard("appointment-details");
                }}
              >
                back to details
              </label>
            </div>
          )}

          <div className="buttons-container">
            <button
              className="close-button"
              onClick={() => {
                closePopup();
              }}
            >
              CLOSE
            </button>
            <button
              className="middle-button"
              onClick={() => {
                setActiveCard("cancel-appointment");
              }}
            >
              {appointment.customer.name.first !== "Time Off" ? "CANCEL APPOINTMENT" : "CANCEL TIME OFF"}
              
            </button>
            {appointment.customer.name.first !== "Time Off" && (
              <button
                className="check-in-button"
                onClick={() => {
                  setActiveCard("check-in");
                }}
              >
                CHECK IN
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
