export default function CopyRightFooter() {
  return (
    <div className="copy-right-footer-container">
      <div className="copy-right-message">
        <p>Copyright, Lis Nails & Spa 2022. All rights reserved.</p>
      </div>
      <div className="terms-and-conditions-message">
        {/* <p>Terms & Conditions</p> */}
      </div>
    </div>
  );
}
