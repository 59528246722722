import { useState } from "react";
import CollapsibleContainer from "../collapsible-container";
import NailServicesMobile from "../nail-services-mobile";
import PedicureServices from "../pedicure-services";
import DateSelection from "../date-selection";
import TimeSelection from "../time-selection";
import PaymentForm from "../payment-form";
import ConfirmationForm from "../confirmation-form";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../Firebase";
import LoadingSpinner from "../loading-spinner";
import ErrorPopup from "../error-popup";
import LoadingBar from "../loading-bar";
import SuccessPopup from "../success-popup";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import KidsServices from "../kids-services/index";
import ZelleTransaction from "../zelle-transaction";
import MenServices from "../men-services";
import SoakOffServices from "../soak-off-services";
import AddOnServices from "../add-on-services";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function StepperCustom() {
  const [activeStep, setActiveStep] = useState("step-1");
  const [manicure, setManicure] = useState("");
  const [pedicure, setPedicure] = useState("");
  const [kidService, setKidService] = useState("");
  const [menService, setMenService] = useState([]);
  const [addOnServices, setAddOnServices] = useState([]);
  const [soakOffService, setSoakOffService] = useState("");
  const [day, setDay] = useState("");
  const [time, setTime] = useState("");
  const [ccLastFour, setCcLastFour] = useState("");
  const [zelleConfirmation, setZelleConfirmation] = useState("");
  const [availableTimes, setAvailableTimes] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [paymentMethodId, setPaymentMethodId] = useState("");

  // control loading bar states
  const [availableTimesLoading, setAvailableTimesLoading] = useState(false);
  const [bookAppointmentLoading, setBookAppointmentLoading] = useState(false);

  // control popup states
  const [availableTimesError, setAvailableTimesError] = useState(false);
  const [enableSuccessPopup, setEnableSuccessPopup] = useState(false);

  // Cloud functions
  const getAvailableTimesAPI = httpsCallable(functions, "getAvailableTimes");
  const bookAppointmentAPI = httpsCallable(functions, "bookAppointment");

  function handleNext(step) {
    setActiveStep(step);
  }

  /**
   * Resets all states to default values.
   */
  function resetAllFields() {
    setActiveStep("step-1");
    setManicure("");
    setPedicure("");
    setKidService("");
    setMenService([]);
    setAddOnServices([]);
    setSoakOffService("");
    setDay("");
    setTime("");
    setCcLastFour("");
    setAvailableTimes([]);
    setErrorMessage("");
    setSuccessMessage("");
    setAvailableTimesLoading(false);
    setBookAppointmentLoading(false);
    setAvailableTimesError(false);
    setEnableSuccessPopup(false);
    setPaymentMethodId("");
  }

  /**
   * Call cloud function to get all available times.
   * @param {request} request
   */
  async function getAvailableTimes(request) {
    setAvailableTimesLoading(true);

    await getAvailableTimesAPI(request)
      .then((response) => {
        setAvailableTimesLoading(false);

        if (response.data === null) {
          setAvailableTimesError(true);
          setErrorMessage(
            "There are no appointments available in the selected day. Please try another day, thank you!"
          );
        } else {
          setAvailableTimes(convertTimesToUTC(response.data));
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }

  /**
   * Updates the error message accordingly and displays error popup.
   * @param {error} error
   */
  function handleError(error) {
    if (error.message === "Payment method does not exists.") {
      setErrorMessage("Payment method is invalid.");
    } else if (error.code === "functions/invalid-argument") {
      setErrorMessage(
        "The information provided is not valid, please try changing your selection. Thank you!"
      );
    } else if (error.code === "functions/unavailable") {
      setErrorMessage(
        "An error has occurred while trying to connect to the database. Please try again later, thank you!"
      );
    } else if (error.code === "functions/already-exists") {
      setErrorMessage(
        "The requested time and day is unavailable due to another appointment already exists at he same time. Try another day or time, thank you!"
      );
    } else {
      setErrorMessage(
        "An unknown error has occurred. Please try again later, thank you!"
      );
    }

    setAvailableTimesLoading(false);
    setAvailableTimesError(true);
  }

  /**
   * Creates a list of UTC date objects. The dates received are
   * changed based on the computer's timezone, so we need to
   * change them back to UTC to represent the same times from the
   * cloud function's response.
   * @param {times} times
   * @returns utc times
   */
  function convertTimesToUTC(times) {
    const computeDate = new Date();
    const offset = computeDate.getTimezoneOffset();

    const utcTimes = times.map((time) => {
      // available times based on timezone of the computer.
      const date = new Date(time);

      // convert to utc time
      const utcDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes() + offset
      );
      return utcDate;
    });

    return removePassedHours(utcTimes, computeDate);
  }

  /**
   * Filters all times that are passed.
   * @param {times} times
   * @param {currentTime} currentTime
   * @returns
   */
  function removePassedHours(times, currentTime) {
    let newTimes = [];

    for (let time of times) {
      if (currentTime.getTime() < time.getTime()) {
        newTimes.push(time);
      }
    }

    return newTimes;
  }

  /**
   * Updates the day selected and calls cloud function.
   * @param {daySelected} daySelected
   */
  function handleDateSelection(daySelected) {
    const todays = daySelected.split("-");
    const date = new Date(todays[0], todays[1] - 1, todays[2]);
    setDay(date);

    // call cloud function.
    getAvailableTimes(buildDateRequest(date));
  }

  /**
   * Build the information required to search for available times.
   * @param {date} date
   * @returns json request object.
   */
  function buildDateRequest(date) {
    const pedicureDuration = pedicure ? pedicure.duration : 0;
    const manicureDuration = manicure ? manicure.duration : 0;
    const kidServiceDuration = kidService ? kidService.duration : 0;
    const menServiceDuration =
      menService.length !== 0 ? sumServicesDuration(menService) : 0;
    const addOnServiceDuration =
      addOnServices.length !== 0 ? sumServicesDuration(addOnServices) : 0;
    const appointmentDuration =
      manicureDuration +
      pedicureDuration +
      kidServiceDuration +
      menServiceDuration +
      addOnServiceDuration;

    return {
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
      appointmentDuration: appointmentDuration,
    };
  }

  function sumServicesDuration(services) {
    let sum = 0;

    for (var service of services) {
      sum += service.duration;
    }

    return sum;
  }

  /**
   * Handles closing the popup error window.
   */
  function closeErrorPopup() {
    setAvailableTimesError(!availableTimesError);
    setErrorMessage("");
  }

  /**
   * Attempts to create an appointment. If the request day and time
   * is available, then it will be created.
   */
  async function confirmAppointment() {
    setBookAppointmentLoading(true);

    await bookAppointmentAPI(buildBookAppointmentRequest())
      .then((response) => {
        setBookAppointmentLoading(false);
        setSuccessMessage(
          "Your appointment has been created. Thank you! Your confirmation number is: " +
            response.data
        );
        setEnableSuccessPopup(true);
      })
      .catch((error) => {
        setBookAppointmentLoading(false);
        handleError(error);
      });
  }

  /**
   * Creates the request with all the data required to book an appointment.
   * @returns request
   */
  function buildBookAppointmentRequest() {
    const appointmentTime = new Date(time);

    return {
      date: {
        day: day.getDate(),
        month: day.getMonth(),
        year: day.getFullYear(),
      },
      services: {
        manicure: manicure,
        pedicure: pedicure,
        kidService: kidService,
        menService: menService,
        soakOffService: soakOffService,
        addOnServices: addOnServices,
      },
      time: {
        hour: appointmentTime.getHours(),
        minute: appointmentTime.getMinutes(),
      },
      paymentMethodId: paymentMethodId,
      zelleConfirmation: zelleConfirmation,
    };
  }

  /**
   *
   * @param {cc4} cc4
   * @param {paymentId} paymentId
   */
  function updateCardSelection(cc4, paymentId) {
    setCcLastFour(cc4);
    setPaymentMethodId(paymentId);
  }

  /**
   * Resets service selection and goes back to the selection.
   * @param {step} step
   */
  function backToServiceSelection(step) {
    setManicure("");
    setPedicure("");
    setKidService("");
    setMenService([]);
    setAddOnServices([]);
    setSoakOffService("");
    handleNext(step);
  }

  /**
   * Resets day and time selection as well as the available times array.
   * Then goes back to Day and Time selection.
   * @param {step} step
   */
  function backToDayTimeSelection(step) {
    setDay("");
    setTime("");
    setAvailableTimes([]);
    handleNext(step);
  }

  function backToPaymentMethod(step) {
    setCcLastFour("");
    setPaymentMethodId("");
    handleNext(step);
  }

  return (
    <div className="stepper-custom-container">
      {enableSuccessPopup && (
        <SuccessPopup message={successMessage} closePopup={resetAllFields} />
      )}
      {availableTimesError && (
        <ErrorPopup
          message={errorMessage}
          closePopup={() => {
            closeErrorPopup();
          }}
        />
      )}

      {bookAppointmentLoading && (
        <LoadingSpinner message="Booking appointment..." />
      )}

      <div className="sub-titles">
        <div
          className={`step-1 middle-line ${
            activeStep === "step-1" ? "step-active" : ""
          }`}
        >
          Service
        </div>
        <div
          className={`step-2 middle-line ${
            activeStep === "step-2" ? "step-active" : ""
          }`}
        >
          Day & time
        </div>
        <div
          className={`step-3 middle-line ${
            activeStep === "step-3" ? "step-active" : ""
          }`}
        >
          Payment
        </div>
        <div
          className={`step-4 ${activeStep === "step-4" ? "step-active" : ""}`}
        >
          Review
        </div>
      </div>

      <div className="content">
        {activeStep === "step-1" && (
          <div className="services-container card">
            <div className="title">Services</div>
            <div className="services-content">
              <div className="services-collapsable">
                <p>
                  Prior to receiving services, if you currently have product on
                  your nails that need removal, please add the appropriate
                  removal service to ensure that we can have a healthy and clean
                  canvas.
                </p>

                <div className="soak-off">
                  <CollapsibleContainer
                    title="Soak Off Services"
                    content={
                      <SoakOffServices callParentFunction={setSoakOffService} />
                    }
                  />
                </div>
                <div className="manicures">
                  <CollapsibleContainer
                    title="Manicure"
                    content={
                      <NailServicesMobile callParentFunction={setManicure} />
                    }
                  />
                </div>

                <div className="pedicures">
                  <CollapsibleContainer
                    title="Pedicure"
                    content={
                      <PedicureServices callParentFunction={setPedicure} />
                    }
                  />
                </div>
                <div className="kids">
                  <CollapsibleContainer
                    title="Kids Services - under 11 years old"
                    content={
                      <KidsServices callParentFunction={setKidService} />
                    }
                  />
                </div>
                <div className="men">
                  <CollapsibleContainer
                    title="Men Services"
                    content={<MenServices callParentFunction={setMenService} />}
                  />
                </div>
                <div className="add-ons">
                  <CollapsibleContainer
                    title="Add Ons"
                    content={
                      <AddOnServices callParentFunction={setAddOnServices} />
                    }
                  />
                </div>
              </div>
            </div>
            <button
              className="next-button"
              disabled={
                manicure === "" &&
                pedicure === "" &&
                kidService === "" &&
                menService === "" &&
                soakOffService === "" &&
                addOnServices === ""
              }
              onClick={() => {
                handleNext("step-2");
              }}
            >
              Next
            </button>
          </div>
        )}
        {activeStep === "step-2" && (
          <div className="day-container card">
            <div className="title">Day & Time</div>
            <div className="date-content">
              <DateSelection callParentFunction={handleDateSelection} />

              {availableTimesLoading && (
                <LoadingBar title="searching for time slots" />
              )}

              {availableTimes.length !== 0 && (
                <TimeSelection
                  times={availableTimes}
                  callParentFunction={setTime}
                />
              )}

              {availableTimes.length === 0 && (
                <div className="warning-no-appts">
                  <label>
                    There are no available appointments on the selected day.
                  </label>
                </div>
              )}
            </div>

            <button
              className="back-button"
              onClick={() => {
                backToServiceSelection("step-1");
              }}
            >
              Back
            </button>
            <button
              className="next-button"
              disabled={day === "" || time === ""}
              onClick={() => {
                handleNext("step-3");
              }}
            >
              Next
            </button>
          </div>
        )}

        {activeStep === "step-3" && (
          <div className="payment-container card">
            <div className="title">Payment method</div>

            <div className="credit-card-step">
              <CollapsibleContainer
                title="Credit Card"
                content={
                  <Elements stripe={stripePromise}>
                    <PaymentForm
                      callParentFunction={updateCardSelection}
                      callParentNextFunc={() => {
                        handleNext("step-4");
                      }}
                    />
                  </Elements>
                }
              />
            </div>

            <div className="credit-card-step">
              <CollapsibleContainer
                title="Zelle Transaction"
                content={
                  <ZelleTransaction
                    updateZelleConfirmation={setZelleConfirmation}
                  />
                }
              />
            </div>
            <button
              className="back-button"
              onClick={() => {
                backToDayTimeSelection("step-2");
              }}
            >
              Back
            </button>
            <button
              className="next-button"
              disabled={ccLastFour === "" && zelleConfirmation === ""}
              onClick={() => {
                handleNext("step-4");
              }}
            >
              Next
            </button>
          </div>
        )}
        {activeStep === "step-4" && (
          <div className="confirmation-container card">
            <div className="title">Review Selection</div>
            <div className="confirmation-content">
              <ConfirmationForm
                appointmentData={{
                  manicure: manicure,
                  pedicure: pedicure,
                  kidService: kidService,
                  menService: menService,
                  soakOffService: soakOffService,
                  addOnServices: addOnServices,
                  day: day,
                  time: time,
                  cc: ccLastFour,
                  zelleConfirmation: zelleConfirmation,
                }}
              />
            </div>
            <button
              className="back-button"
              onClick={() => {
                backToPaymentMethod("step-3");
              }}
            >
              Back
            </button>
            <button
              className="next-button"
              onClick={() => {
                confirmAppointment();
              }}
            >
              Confirm
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
