import React, { useState } from "react";
import { buildOptions } from "../../utils/common-functions";
import { services } from "../../utils/variables";

export default function NailServicesMobile(props) {
  const { callParentFunction } = props;

  const [acrylicService, setAcrylicService] = useState({});
  const [dipPowderService, setDipPowderService] = useState({});
  const [gelX, setGelX] = useState({});
  const [flexGel, setFlexGel] = useState({});
  const [manicureService, setManicureService] = useState({});
  const [regularPolishChange, setRegularPolishChange] = useState({});
  const [gelPolishChange, setGelPolishChange] = useState({});

  /**
   * Handles selection of service. Only one dropdown should be set
   * at the time. Only one manicure service or only one pedicure service.
   * They can select one manicure and one pedicure, but not two of
   * the same service manicure x2 or pedicure x2.
   * @param {service} service
   * @param {value} value
   */
  function serviceSelection(service, value) {
    if (service === "acrylic") {
      setAcrylicService(value);
      setDipPowderService("default");
      setManicureService("default");
      setRegularPolishChange("default");
      setGelPolishChange("default");
    } else if (service === "dip-powder") {
      setAcrylicService("default");
      setDipPowderService(value);
      setManicureService("default");
      setRegularPolishChange("default");
      setGelPolishChange("default");
    } else if (service === "gelX") {
      setAcrylicService("default");
      setDipPowderService("default");
      setManicureService("default");
      setRegularPolishChange("default");
      setGelPolishChange("default");
      setGelX(value);
    } else if (service === "flexGel") {
      setAcrylicService("default");
      setDipPowderService("default");
      setManicureService("default");
      setRegularPolishChange("default");
      setGelPolishChange("default");
      setGelX("default");
      setFlexGel(value);
    } else if (service === "manicure") {
      setAcrylicService("default");
      setDipPowderService("default");
      setManicureService(value);
      setRegularPolishChange("default");
      setGelPolishChange("default");
    } else if (service === "regular-polish-change") {
      setAcrylicService("default");
      setDipPowderService("default");
      setManicureService("default");
      setRegularPolishChange(value);
      setGelPolishChange("default");
    } else if (service === "gel-polish-change") {
      setAcrylicService("default");
      setDipPowderService("default");
      setManicureService("default");
      setRegularPolishChange("default");
      setGelPolishChange(value);
    }

    value !== "default"
      ? callParentFunction(JSON.parse(value))
      : callParentFunction("");
  }

  return (
    <div className="nail-services-container">
      <div className="acrylics-container">
        <label>Acrylics:</label>
        <select
          name="acrylics"
          value={acrylicService}
          onChange={(event) => serviceSelection("acrylic", event.target.value)}
        >
          <option value="default">Select one...</option>
          {buildOptions(services.nailServices.acrylicServices)}
        </select>
      </div>
      <div className="dip-powders-container">
        <label>Dip Powders:</label>
        <select
          name="dip-powders"
          value={dipPowderService}
          onChange={(event) =>
            serviceSelection("dip-powder", event.target.value)
          }
        >
          <option value="default">Select one...</option>
          {buildOptions(services.nailServices.dipPowderServices)}
        </select>
      </div>
      <div className="dip-powders-container">
        <label>Gel X:</label>
        <select
          name="gel-x"
          value={gelX}
          onChange={(event) => {
            serviceSelection("gelX", event.target.value);
          }}
        >
          <option value="default">Select one...</option>
          {buildOptions(services.nailServices.gelXServices)}
        </select>
      </div>
      <div className="dip-powders-container">
        <label>Rubber:</label>
        <select
          name="rubber"
          value={flexGel}
          onChange={(event) => {
            serviceSelection("flexGel", event.target.value);
          }}
        >
          <option value="default">Select one...</option>
          {buildOptions(services.nailServices.rubberServices)}
        </select>
      </div>
      <div className="manicures-container">
        <label>Manicures:</label>
        <select
          name="manicures"
          value={manicureService}
          onChange={(event) => serviceSelection("manicure", event.target.value)}
        >
          <option value="default">Select one...</option>
          {buildOptions(services.nailServices.manicures)}
        </select>
      </div>
      <div className="nail-polish-container">
        <label>Regular Polish Change:</label>
        <select
          name="regular-polish-change"
          value={regularPolishChange}
          onChange={(event) =>
            serviceSelection("regular-polish-change", event.target.value)
          }
        >
          <option value="default">Select one...</option>
          {buildOptions(services.nailServices.polishChanges.regularPolish)}
        </select>
      </div>
      <div className="nail-polish-container">
        <label>Gel Polish Change:</label>
        <select
          name="gel-polish-change"
          value={gelPolishChange}
          onChange={(event) =>
            serviceSelection("gel-polish-change", event.target.value)
          }
        >
          <option value="default">Select one...</option>
          {buildOptions(services.nailServices.polishChanges.gelPolish)}
        </select>
      </div>
    </div>
  );
}
