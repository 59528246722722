import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import userAtom from "../../recoil/atoms/user";

export default function Footer() {
  const navigate = useNavigate();

  const user = useRecoilValue(userAtom);

  function navigateTo(path) {
    navigate(path);
    window.scroll(0, 0);
  }
  return (
    <div className="footer-container">
      <div className="footer-logo-message-container">
        <img
          className="footer-logo-img"
          src={require("../../assets/images/lis-spa-logo.png")}
          alt="company-logo"
        />
        <p className="footer-intro-message">
          Professionalism will be our commitment and your satisfaction our
          promise.
        </p>
        <div className="footer-social-media-container">
          <img
            onClick={() => {
              window.open("https://www.instagram.com/lisnails_spa/", "_blank");
            }}
            className="footer-logo-img"
            src={require("../../assets/images/instagram-logo.png")}
            alt="instagram-logo"
          />
          <img
            onClick={() =>
              window.open("https://www.facebook.com/lisnails.spa1/", "_blank")
            }
            className="footer-logo-img"
            src={require("../../assets/images/facebook-blue.png")}
            alt="instagram-logo"
          />
          <img
            onClick={() => window.open("http://wa.me/13178684301", "_blank")}
            className="footer-logo-img"
            src={require("../../assets/images/whatssap-green.png")}
            alt="instagram-logo"
          />
        </div>
      </div>
      <div className="footer-links-container">
        <div className="product-container">
          <h3>Product</h3>
          <a href="http://lisnailsandspa.booksy.com/g/">
            <label
            // onClick={() => {
            //   navigateTo("/book-appointment");
            // }}
            >
              Book appointment
            </label>
          </a>

          <label
            onClick={() => {
              navigateTo("/cancel-appointment");
            }}
          >
            Cancel appointment
          </label>
        </div>
        <div className="about-us-container">
          <h3>About Us</h3>
          <a href="#faq-anchor">
            <label
              onClick={() => {
                navigate("/cancel-appointment");
              }}
            >
              FAQ
            </label>
          </a>
          {user === null && (
            <label
              onClick={() => {
                navigateTo("/login");
              }}
            >
              Log in
            </label>
          )}
        </div>
      </div>
    </div>
  );
}
