export default function LoadingBar(props) {
  const { title } = props;
  return (
    <div className="loading-bar-container">
      <div className="loading-bar-title">{title}</div>
      <div className="loader">
        <div className="circle"></div>
      </div>
    </div>
  );
}
