import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../Firebase";
import LoadingSpinner from "../loading-spinner";
import ErrorPopup from "../error-popup";
import SuccessPopup from "../success-popup";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../utils/common-functions";
import { useNavigate } from "react-router-dom";

export default function CreateAccountForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [createUserLoading, setCreateUserLoading] = useState(false);
  const [createUserError, setCreateUserError] = useState(false);
  const [emailErrorExists, setEmailErrorExists] = useState(false);
  const [phoneErrorExists, setPhoneErrorExists] = useState(false);
  const [errorWeakPassword, setErrorWeakPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [createAccountSuccess, setCreateAccountSuccess] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [validFormError, setValidFormError] = useState(false);

  const navigate = useNavigate();

  const createUserAPI = httpsCallable(functions, "createCustomerUser");

  function resetForm() {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setPassword("");
    setConfirmPassword("");
    setCreateUserLoading(false);
    setCreateUserError(false);
    setMessage("");
    setCreateAccountSuccess("");
    setEmailError(false);
    setPhoneError(false);
    setPasswordError(false);
    setValidFormError(false);
    setEmailErrorExists(false);
    setPhoneErrorExists(false);
  }

  function resetPasswordFields() {
    setPassword("");
    setConfirmPassword("");
    setErrorWeakPassword(false);
  }

  function resetEmailField() {
    setEmail("");
    setEmailErrorExists(false);
  }

  function resetPhoneNumberField() {
    setPhoneNumber("");
    setPhoneErrorExists(false);
  }

  function resetGoToLogin() {
    resetForm();
    navigate("/resend-verification-email");
  }

  /**
   * Attempts to create an account.
   */
  async function createUser() {
    if (validateForm()) {
      setCreateUserLoading(true);

      await createUserAPI(buildCreateUserRequest())
        .then((response) => {
          setCreateUserLoading(false);
          setCreateAccountSuccess(true);
          setMessage(
            "Your account has been created! An email has been sent with a verification link. Please check your spam folder or your promotion tab."
          );
        })
        .catch((error) => {
          handleError(error);
        });
    }
  }

  /**
   * Checks if all fields in the form are valid.
   * @returns boolean
   */
  function validateForm() {
    var valid = false;
    if (
      validateEmail(email) &&
      validatePhoneNumber(phoneNumber) &&
      password === confirmPassword &&
      firstName !== "" &&
      lastName !== ""
    ) {
      setValidFormError(false);
      valid = true;
    } else {
      setValidFormError(true);
    }

    return valid;
  }

  /**
   * Sets the error messaging according with the error given.
   * @param {error} error
   */
  function handleError(error) {
    setCreateUserLoading(false);

    if (error.message === "Password entered is weak.") {
      setMessage(
        "The password you entered is weak. Please try at least six characters, at least one capital letter, and at least one number. Thank you!"
      );
      setErrorWeakPassword(true);
    } else if (error.message === "Invalid phone number.") {
      setMessage(
        "The phone number provided is invalid, please try again with a valid phone number."
      );
      setPhoneErrorExists(true);
    } else if (error.message === "The emailed provided is invalid.") {
      setMessage(
        "The email provided is invalid. Please try again with a valid email address. Thank you!"
      );
      setEmailErrorExists(true);
    } else if (
      error.message ===
      "Phone number provided is associated with another account."
    ) {
      setMessage(error.message);
      setPhoneErrorExists(true);
    } else if (error.code === "failed-precondition") {
      setMessage(
        "The provided password is weak, please try using a more complex password using at least one capital letter, and one symbol. Thank you!"
      );
      setErrorWeakPassword(true);
    } else if (
      error.message === "Email provided is associated with another account."
    ) {
      setMessage(error.message);
      setEmailErrorExists(true);
    } else if (error.code === "functions/invalid-argument") {
      setMessage(
        "One or more fields are invalid, please enter valid information and try again. Thank you!"
      );
      setCreateUserError(true);
    } else {
      setMessage(
        "An unknown error has occurred. Please try again later, thank you!"
      );
      setCreateUserError(true);
    }
  }

  /**
   * Creates the JSON containing all the required information
   * to create an user.
   * @returns request
   */
  function buildCreateUserRequest() {
    return {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phoneNumber,
      password: password,
    };
  }

  /**
   * Checks if the email entered is valid.
   * @param {value} value
   */
  function updateEmail(value) {
    setEmail(value);

    if (!validateEmail(value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }

  /**
   * Checks the phone number is one of the following formats.
   * (123) 456-7890
   * (123)456-7890
   * 123-456-7890
   * 1234567890
   * @param {value} value
   */
  function validatePhone(value) {
    setPhoneNumber(value);
    if (validatePhoneNumber(value)) {
      setPhoneError(false);
    } else {
      setPhoneError(true);
    }
  }

  /**
   * Checks if the password and confirm password are the same value.
   * @param {value} value
   */
  function validatePassword(value) {
    setConfirmPassword(value);
    password === value ? setPasswordError(false) : setPasswordError(true);
  }

  function areBothPasswordsEqual(value) {
    value === confirmPassword
      ? setPasswordError(false)
      : setPasswordError(true);
  }

  return (
    <div className="create-acct-container">
      {createUserLoading && (
        <LoadingSpinner message="Creating account..." closePopup={resetForm} />
      )}
      {createUserError && (
        <ErrorPopup message={message} closePopup={resetForm} />
      )}
      {emailErrorExists && (
        <ErrorPopup message={message} closePopup={resetEmailField} />
      )}

      {errorWeakPassword && (
        <ErrorPopup message={message} closePopup={resetPasswordFields} />
      )}

      {phoneErrorExists && (
        <ErrorPopup message={message} closePopup={resetPhoneNumberField} />
      )}
      {createAccountSuccess && (
        <SuccessPopup message={message} closePopup={resetGoToLogin} />
      )}

      <div className="create-acct-image-container">
        <img
          src={require("../../assets/images/pinturas.jpg")}
          alt="company-logo"
        />
      </div>
      <div className="create-acct-form-container">
        <h2>Sign up & Join with us!</h2>
        <div className="name-container">
          <div className="first-name-container">
            <label>First name *</label>
            <input
              type="text"
              id="first-name"
              name="First Name"
              placeholder="John"
              value={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
            />
          </div>
          <div className="last-name-container">
            <label>Last name *</label>
            <input
              type="text"
              id="last-name"
              name="Last Name"
              placeholder="Smith"
              value={lastName}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
            />
          </div>
        </div>

        <div className="contact-container">
          <div className="email-container">
            <label>Email address *</label>
            <input
              type="email"
              id="email"
              name="Email"
              placeholder="example@gmail.com"
              value={email}
              onChange={(event) => {
                updateEmail(event.target.value);
              }}
            />
            {emailError && (
              <div className="email-error-container">
                <label className="email-error">
                  Please, enter a valid email.
                </label>
              </div>
            )}
          </div>
          <div className="phone-number-container">
            <label>Phone number *</label>
            <input
              type="text"
              id="phone"
              placeholder="(212)456-7890"
              name="phone"
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              value={phoneNumber}
              onChange={(event) => {
                validatePhone(event.target.value);
              }}
            />
            {phoneError && (
              <div className="phone-error-container">
                <label className="phone-error">
                  Please, enter a valid phone number.
                </label>
              </div>
            )}
          </div>
        </div>

        <div className="password-colum-container">
          <div className="password-container">
            <label>Password *</label>
            <input
              type="password"
              id="password"
              name="Password"
              placeholder="*********"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
                areBothPasswordsEqual(event.target.value);
              }}
            />
          </div>
          <div className="confirm-password-container">
            <label>Confirm password *</label>
            <input
              type="password"
              id="confirm-password"
              name="Confirm password"
              placeholder="*********"
              value={confirmPassword}
              onChange={(event) => {
                validatePassword(event.target.value);
              }}
            />
            {passwordError && (
              <div className="password-error-container">
                <label className="password-error">
                  Passwords do not match.
                </label>
              </div>
            )}
          </div>
        </div>

        <div className="create-acct-button">
          <button
            onClick={() => {
              createUser();
            }}
          >
            Create Account
          </button>
          {validFormError && (
            <div className="form-error-container">
              <label className="form-error">
                Missing information. Please fields all fields above.
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
