export default function QuestionCard(props) {
  const { question, answer, purpleBackground } = props;
  return (
    <div
      className={`question-card-container ${
        purpleBackground ? "purple-background" : ""
      }`}
    >
      <h3>{question}</h3>
      <p>{answer}</p>
    </div>
  );
}
