import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { functions } from "../../../Firebase";
import ErrorPopup from "../../error-popup";
import LoadingBar from "../../loading-bar";
import SuccessPopup from "../../success-popup";

export default function CancelAppointmentDetailsCard(props) {
  const { appointment, cancelAppointmentParentFunction, closePopup } = props;

  const [cancelAppointmentLoading, setCancelAppointmentLoading] =
    useState(false);
  const [cancelAppointmentError, setCancelAppointmentError] = useState(false);
  const [cancelAppointmentSuccess, setCancelAppointmentSuccess] =
    useState(false);
  const [message, setMessage] = useState("");

  const cancelAppointmentAPI = httpsCallable(functions, "cancelAppointment");

  /**
   * Resets all states values to default value and closes the popup.
   */
  function resetForm() {
    setCancelAppointmentLoading(false);
    setCancelAppointmentError(false);
    setCancelAppointmentSuccess(false);
    setMessage("");
    closePopup();
  }

  /**
   * Calls cloud function to cancel appointment.
   */
  async function cancelAppointment() {
    setCancelAppointmentLoading(true);

    await cancelAppointmentAPI(appointment.id)
      .then((response) => {
        setCancelAppointmentLoading(false);
        setCancelAppointmentSuccess(true);
        cancelAppointmentParentFunction(appointment);
        setMessage("The appointment has been cancelled");
      })
      .catch((error) => {
        setCancelAppointmentLoading(false);
        setMessage(
          "An error occurred while cancelling this appointment. Please try again later, thank you"
        );
        setCancelAppointmentError(true);
      });
  }

  return (
    <div className="card-content">
      {cancelAppointmentError && (
        <ErrorPopup message={message} closePopup={resetForm} />
      )}
      {cancelAppointmentSuccess && (
        <SuccessPopup message={message} closePopup={resetForm} />
      )}

      <div className="appt-details-cancel">
        {appointment.status === "not checked-in" && (
          <p>Are you sure you want to cancel this appointment?</p>
        )}
        {appointment.status !== "not checked-in" && (
          <p>
            This appointment cannot be cancel because the status is{" "}
            {appointment.status}.
          </p>
        )}

        {appointment.status === "not checked-in" && (
          <div>
            <button
              disabled={
                appointment.status !== "not checked-in" ||
                cancelAppointmentLoading
              }
              onClick={() => {
                cancelAppointment();
              }}
            >
              YES CANCEL
            </button>
          </div>
        )}

        {cancelAppointmentLoading && (
          <LoadingBar title={"Cancelling appointment..."} />
        )}
      </div>
    </div>
  );
}
