import { useNavigate } from "react-router-dom";

export default function MustLoginFirst() {
  const navigate = useNavigate();
  return (
    <div className="must-login-first-container">
      <div className="must-login-first-button">
        <h3>You must be logged in to book your appointment</h3>
        <button
          onClick={() => {
            navigate("/login");
            window.scrollTo(0, 0);
          }}
        >
          Login
        </button>
        <h3>Don't have an account? Create one here</h3>
        <button
        className="create-account-button"
          onClick={() => {
            navigate("/create-account");
            window.scrollTo(0, 0);
          }}
        >
          Create Account
        </button>
      </div>
      <div className="log-in-img-container">
        <img
          className="footer-logo-img"
          src={require("../../assets/images/must-log-in.jpg")}
          alt="company-logo"
        />
      </div>
    </div>
  );
}
